import { configureStore, getDefaultMiddleware, Reducer } from '@reduxjs/toolkit'
import createReducer from 'store/rootReducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer.createReducer())
  })
}

const middlewares = [...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false })]

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`)
  const logger = createLogger({
    collapsed: (getState: Function, action: Object, logEntry: Object | any) => !logEntry.error,
  })

  middlewares.push(logger)
}

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
})

declare interface LooseObject {
  [key: string]: any
}

const asyncReducers: LooseObject = {}

// store.asyncReducers = {}

export const injectReducer = <State>(key: string, reducer: Reducer<State>) => {
  // if (store.asyncReducers[key]) {
  if (asyncReducers[key]) {
    return false
  }
  // store.asyncReducers[key] = reducer
  // store.replaceReducer(createReducer(store.asyncReducers))
  asyncReducers[key] = reducer
  store.replaceReducer(createReducer(asyncReducers))
  return store
}

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
