import { ECBasicOption } from 'echarts/types/dist/shared'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import echarts from '../../lib/echarts/echarts'
import chartThemesConfig from '../../lib/theme/themesConfig'
import { lineGradient } from '../../lib/echarts/chartUtils'
import { usePrevious } from '../../@btw/hooks'
import { pivot2d, transformData, calcHeight } from '../../lib/echarts/chartUtils'
import _ from 'lodash'
import {
  ChartsProps,
  toolboxDefault,
  fontBold,
  fontNormal,
  labelShadow,
} from '../../lib/echarts/chartVariable'

export interface IDynamicOptions {
  xData: any[]
  yData: any[]
  yName: string
  visualMax: number
}

const initialDynamicOptions = {
  xData: [],
  yData: [],
  yName: '',
  visualMax: 0,
}

function BarChart({ wsData, optionValues }: ChartsProps) {
  const chartRef = useRef<echarts.ECharts | null>(null)
  const [data, setData] = useState<any[]>([])
  const [dynamicOptions, setDynamicOptions] = useState(initialDynamicOptions)

  const {
    pivot,
    item3D,
    axisX,
    axisY,
    gridLeft,
    gridBottom,
    axisXRotate,
    axisYRotate,
    widthRatio,
    heightRatio,
    theme,
    labelShow,
    labelPosition,
    itemFontSize,
    itemFontWeight,
    itemFontColor,
    labelShadowShow,
    labelShadowColor,
  } = optionValues

  const prevOptionValues = usePrevious(optionValues)
  const optionValuesChanged = !_.isEqual(optionValues, prevOptionValues)

  useEffect(() => {
    if (wsData.length === 0) return
    const foo = pivot ? pivot2d(wsData) : wsData
    const bar = transformData(foo)
    setData(bar.data2d)

    const { xData, yData, yName, visualMax } = bar
    setDynamicOptions({ xData, yData, yName, visualMax })
  }, [wsData, pivot])

  const seriesData = useMemo(() => {
    if (data.length === 0) return null

    const orient = axisX === 'value' && axisY === 'category' ? false : true

    return data.reduce((p, c, i, array) => {
      p.push({
        type: 'bar',
        name: dynamicOptions.yData[i],
        data: c,
        itemStyle: item3D
          ? {
              color: function (params: any) {
                return lineGradient(params.color, orient)
              },
            }
          : undefined,
      })
      return p
    }, [])
  }, [data, optionValues])

  const chartHeight = useMemo(() => calcHeight(heightRatio), [heightRatio])

  const chartOptions = useMemo(() => {
    const labelShadowObj = labelShadowShow ? labelShadow : undefined

    const options: ECBasicOption = {
      tooltip: {
        position: 'top',
      },
      label: {
        show: labelShow,
        position: labelPosition,
        color: itemFontColor ? itemFontColor : 'inherit',
        fontSize: itemFontSize ? itemFontSize : 13,
        fontWeight: itemFontWeight ? itemFontWeight : 'normal',
        ...labelShadowObj,
        textShadowColor: labelShadowColor,
      },
      grid: {
        top: 120,
        left: gridLeft,
        bottom: gridBottom,
      },
      xAxis: {
        type: axisX,
        data: dynamicOptions.xData,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisXRotate, interval: 0, ...fontBold },
      },
      yAxis: {
        type: axisY,
        axisLabel: { rotate: axisYRotate, interval: 0, ...fontBold },
      },
      series: seriesData,
      toolbox: toolboxDefault,
      brush: {},
      legend: {
        top: 50,
        left: 'auto',
        right: '10%',
        width: '80%',
        height: '60%',
        type: 'scroll',
        orient: 'horizontal',
        data: dynamicOptions.yData,
        itemWidth: 25,
        itemHeight: 15,
        itemGap: 15,
      },
    }
    return options
  }, [data, optionValues, dynamicOptions])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = divRef.current
    if (!element || !chartOptions) return

    let c: echarts.ECharts | null = chartRef.current ?? null

    if (!c) {
      const chart = echarts.init(element, chartThemesConfig[theme])
      chartRef.current = chart
      c = chart
    }

    c.dispose()
    const chart = echarts.init(element, chartThemesConfig[theme])
    chartRef.current = chart
    c = chart

    c.setOption(chartOptions)

    const handleResize = () => {
      c?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartOptions, optionValuesChanged])

  return (
    <div className="flex w-full h-full">
      <div
        ref={divRef}
        className={`flex ${chartHeight} ${widthRatio === 12 ? 'w-full' : `w-${widthRatio}/12`}`}
      ></div>
    </div>
  )
}

export default BarChart
