import { useEffect, useState, createRef } from 'react'

const MIN_WIDTH = 240

const LeftPane = ({ leftWidth, setLeftWidth, children }) => {
  const leftRef = createRef()

  useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(leftRef.current.clientWidth)
        return
      }

      leftRef.current.style.width = `${leftWidth}px`
    }
  }, [leftRef, leftWidth, setLeftWidth])

  return (
    <div ref={leftRef} style={{ minWidth: '240px', padding: '8px' }}>
      {children}
    </div>
  )
}

export const SplitView = ({ left, right }) => {
  const [leftWidth, setLeftWidth] = useState(undefined)
  const [separatorXPosition, setSeparatorXPosition] = useState(undefined)
  const [dragging, setDragging] = useState(false)

  const splitPaneRef = createRef()

  const onMouseDown = (e) => {
    setSeparatorXPosition(e.clientX)
    setDragging(true)
  }

  const onTouchStart = (e) => {
    setSeparatorXPosition(e.touches[0].clientX)
    setDragging(true)
  }

  const onMove = (clientX) => {
    if (dragging && leftWidth && separatorXPosition) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition
      setSeparatorXPosition(clientX)

      if (newLeftWidth < MIN_WIDTH) {
        setLeftWidth(MIN_WIDTH)
        return
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth

        if (newLeftWidth > splitPaneWidth - MIN_WIDTH) {
          setLeftWidth(splitPaneWidth - MIN_WIDTH)
          return
        }
      }

      setLeftWidth(newLeftWidth)
    }
  }

  const onMouseMove = (e) => {
    e.preventDefault()
    onMove(e.clientX)
  }

  const onTouchMove = (e) => {
    onMove(e.touches[0].clientX)
  }

  const onMouseUp = () => {
    setDragging(false)
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchmove', onTouchMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('touchmove', onTouchMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })

  return (
    <div ref={splitPaneRef} className="flex flex-row items-start h-full w-full">
      <LeftPane leftWidth={leftWidth} setLeftWidth={setLeftWidth}>
        {left}
      </LeftPane>
      <div
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
        className="group transition flex items-center resize-x self-stretch p-2 hover:bg-blue-200 cursor-text"
      >
        <div className="h-full w-1 bg-blue-100 group-hover:bg-blue-200 cursor-text"></div>
      </div>
      <div className="flex-1 p-8">{right}</div>
    </div>
  )
}

export default SplitView
