import _ from 'lodash'

function adjust(color: string, amount: number) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      )
  )
}

const radialGradient = (x: number = 0.4, y: number = 0.3, r: number = 0.5, color: string) => {
  return {
    type: 'radial',
    x: x,
    y: y,
    r: r,
    colorStops: [
      {
        offset: 0,
        color: color,
      },
      {
        offset: 1,
        color: adjust(color, -60),
      },
    ],
    global: false,
  }
}

export default radialGradient

export const lineGradient = (color: string, vertical: boolean = true) => {
  const orient = vertical ? { x: 0, x2: 1, y: 0, y2: 0 } : { x: 0, x2: 0, y: 0, y2: 1 }
  return {
    ...orient,
    type: 'linear',

    colorStops: [
      {
        offset: 0,
        color: color,
      },
      {
        offset: 0.5,
        color: adjust(color, -30),
      },
      {
        offset: 0.5,
        color: adjust(color, -60),
      },
      {
        offset: 1,
        color: adjust(color, -90),
      },
    ],
  }
}

export const pieGradient = (color: string) => {
  return {
    type: 'linear',
    x: 0,
    x2: 0,
    y: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: color,
      },
      {
        offset: 0.1,
        color: adjust(color, -10),
      },
      {
        offset: 0.2,
        color: adjust(color, -20),
      },
      {
        offset: 0.3,
        color: adjust(color, -30),
      },
      {
        offset: 0.5,
        color: adjust(color, -50),
      },
      {
        offset: 1,
        color: adjust(color, -90),
      },
    ],
  }
}

export const diamondGradient = (color1: string) => {
  return {
    type: 'linear',
    x: 0,
    x2: 1,
    y: 0,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: color1,
      },
      {
        offset: 0.5,
        color: color1,
      },
      {
        offset: 0.5,
        color: adjust(color1, -50),
      },
      {
        offset: 1,
        color: adjust(color1, -50),
      },
    ],
  }
}

export function invertHex(hex: string) {
  return (
    '#' + (Number(`0x1${hex.replace(/^#/, '')}`) ^ 0xffffff).toString(16).substr(1).toUpperCase()
  )
}

export function invertColor(hex: string, bw: boolean) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.')
  }
  let r: any = parseInt(hex.slice(0, 2), 16),
    g: any = parseInt(hex.slice(2, 4), 16),
    b: any = parseInt(hex.slice(4, 6), 16)
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return '#' + padZero(r, 2) + padZero(g, 2) + padZero(b, 2)
}

export function padZero(str: number, len: number) {
  len = len || 2
  const zeros = new Array(len).join('0')
  return (zeros + str).slice(-len)
}

export function pivot2d(result: any) {
  return _.zip.apply(_, result) as any
}

function getMax(a: any) {
  return Math.max(...a.map((e: any) => (Array.isArray(e) ? getMax(e) : e)))
}

export function transformData(array: any) {
  // Convert chart data ( heatmap chart not yet support dataset)

  const xData = array ? array[0]?.slice(1) : []
  const yData = array.map((x: any) => x[0])
  const yName = yData.shift()

  const data2d = array.slice(1).map((item: any) => item.slice(1))
  const xLen = data2d[0].length
  const yLen = data2d.length
  const visualMax = getMax(data2d)

  let data = []
  for (let i = 0; i < yLen; i++) {
    for (let j = 0; j < xLen; j++) {
      data.push([j, i, data2d[i][j] ? data2d[i][j] || '-' : data2d[i][j]])
    }
  }
  return { data, data2d, xData, yData, yName, visualMax }
}

export const calcHeight = (heightRatio: number) => {
  switch (heightRatio) {
    case 1:
      return 'h-512'
    case 2:
      return 'h-620'
    case 3:
      return 'h-md'
    case 4:
      return 'h-740'
    case 5:
      return 'h-lg'
    case 6:
      return 'h-xl'
    case 7:
      return 'h-2xl'
    case 8:
      return 'h-3xl'
    case 9:
      return 'h-4xl'
    default:
      return 'h-5xl'
  }
}
