import CustomTheme from 'lib/CustomTheme'
import Layout from 'components/Layout'
import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { selectCurrLangDir } from 'store/i18nSlice'
import withAppProviders from './withAppProviders'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    prepend: true,
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    prepend: true,
  },
}

const App = () => {
  const langDirection = useSelector(selectCurrLangDir)

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <BrowserRouter>
        <CustomTheme>
          <Layout />
        </CustomTheme>
      </BrowserRouter>
    </CacheProvider>
  )
}

export default withAppProviders(App)()
