import { format, parseISO } from 'date-fns'

export function generateGUID() {
  function S4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return S4() + S4()
}

export const gridData = [...Array(20)].map((x) => Array(15).fill(null))

export function weekdayList() {
  function weekdayValues() {
    let res = ['now-5d', 'now-4d', 'now-3d', 'now-2d', 'now-1d', 'now-0d']
    res.splice(0, deleteCount, 'now-3w', 'now-2w', 'now-1w')
    return res
  }
  let weekKeys = ['월요일', '화요일', '수요일', '목요일', '금요일', '오늘']
  const d = new Date()
  const i = d.getDay()
  const start = i === 0 ? 0 : i > 6 ? 5 : i - 1
  const deleteCount = i === 0 ? 5 : 6 - i
  weekKeys.splice(start, deleteCount)
  weekKeys.splice(0, 0, '3주전', '2주전', '지난주')
  const weekValues = weekdayValues()
  return { weekKeys, weekValues }
}

export function dateWithDot(date) {
  return date ? format(parseISO(date), 'yyyy.MM.dd HH:mm') : ''
}

export function numberWithCommas(num) {
  return num === 0 || Number.isNaN(num) ? 0 : Number(num).toLocaleString()
}
