import { combineReducers, ReducersMapObject, Action } from '@reduxjs/toolkit'
import btw from './btw'
import i18n from './i18nSlice';

const createReducer = (asyncReducers?: ReducersMapObject) => (state: any, action: Action) => {
  const combinedReducer = combineReducers({
    btw,
    i18n,    
    ...asyncReducers,
  })
  return combinedReducer(state, action)
}

export default createReducer
