import { ECBasicOption } from 'echarts/types/dist/shared'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import echarts from '../../lib/echarts/echarts'
import chartThemesConfig from '../../lib/theme/themesConfig'
import { usePrevious } from '../../@btw/hooks'
import {
  ChartsProps,
  toolboxDefault,
  fontBold,
  fontNormal,
  labelShadow,
} from '../../lib/echarts/chartVariable'
import _ from 'lodash'
import radialGradient, { pivot2d, transformData, calcHeight } from '../../lib/echarts/chartUtils'
import materialColors from 'lib/materialColors'

export interface IDynamicOptions {
  xData: any[]
  yData: any[]
  yName: string
  visualMax: number
}

const initialDynamicOptions = {
  xData: [],
  yData: [],
  yName: '',
  visualMax: 0,
}

function ScatterChart({ wsData, optionValues }: ChartsProps) {
  const chartRef = useRef<echarts.ECharts | null>(null)
  const [data, setData] = useState<any[]>([])
  const [dynamicOptions, setDynamicOptions] = useState(initialDynamicOptions)

  const {
    pivot,
    ratio,
    item3D,
    gridLeft,
    gridBottom,
    axisXRotate,
    axisYRotate,
    widthRatio,
    heightRatio,
    theme,
    labelShow,
    labelPosition,
    bubbleHeatmap,
    colormap,
    colormapInverse,
    itemFontSize,
    itemFontWeight,
    itemFontColor,
    labelShadowShow,
    labelShadowColor,
  } = optionValues

  const prevOptionValues = usePrevious(optionValues)
  const optionValuesChanged = !_.isEqual(optionValues, prevOptionValues)

  useEffect(() => {
    if (wsData.length === 0) return
    const foo = pivot ? pivot2d(wsData) : wsData
    const bar = transformData(foo)
    setData(bar.data)

    const { xData, yData, yName, visualMax } = bar
    setDynamicOptions({ xData, yData, yName, visualMax })
  }, [wsData, pivot])

  const seriesData = useMemo(() => {
    if (data.length === 0) return null

    // const colorSet = theme ? chartThemesConfig[theme].color : chartThemesConfig.basic.color
    const foo = materialColors[colormap]
    const bar = foo ? Object.values(foo) : []
    const colorSet = colormapInverse ? bar.reverse() : bar

    return data.map((res: any[], idx: number) => {
      // const _color = chartColors[result[1] as any] || chartColors[Math.floor(Math.random() * chartColors.length)]
      return {
        type: 'scatter',
        name: dynamicOptions.xData[idx],
        data: [
          {
            label: {
              show: labelShow,
              formatter: function (param: any) {
                const result = param.value[2] > 0 ? param.value[2] : ''
                return result
              },
              // color: labelPosition === 'inside' ? invertColor(_color, true) : 'black',
              color: itemFontColor ? itemFontColor : 'inherit',
              fontSize: itemFontSize ? itemFontSize : 13,
              fontWeight: itemFontWeight ? itemFontWeight : 'normal',
              position: labelPosition,
            },
            value: res,
          },
        ],
        itemStyle: {
          color: function () {
            // const _color = colorSet[res[1]] || colorSet[Math.floor(Math.random() * colorSet.length)]
            const _color = colorSet[res[1]] || colorSet[res[1] % colorSet.length]
            return item3D ? radialGradient(0.4, 0.3, 0.5, _color) : _color
          },
          opacity: 0.9,
        },
        symbolSize: function (val: number[]) {
          const fixedRate = ratio * 10
          const minSize = 18
          return bubbleHeatmap
            ? fixedRate
            : val[2] > fixedRate
            ? fixedRate
            : val[2] > minSize
            ? val[2]
            : val[2]
            ? minSize
            : undefined
        },
      }
    })
  }, [data, optionValues])

  const chartHeight = useMemo(() => calcHeight(heightRatio), [heightRatio])

  const visualPalette = useMemo(() => {
    const foo = materialColors[colormap]
    const bar = foo ? Object.values(foo) : []
    return colormapInverse ? bar.reverse() : bar
  }, [colormap, colormapInverse])

  const chartOptions = useMemo(() => {
    const labelShadowObj = labelShadowShow ? labelShadow : undefined

    const options: ECBasicOption = {
      tooltip: {
        position: 'top',
      },
      label: {
        show: labelShow,
        position: labelPosition,
        ...fontNormal,
        ...labelShadowObj,
        textShadowColor: labelShadowColor,
      },
      grid: {
        top: 120,
        left: gridLeft,
        bottom: gridBottom,
      },
      xAxis: {
        type: 'category',
        data: dynamicOptions.xData,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisXRotate, margin: 25, interval: 0, ...fontBold },
        boundaryGap: true,
        // name: '분류',
        nameLocation: 'middle',
        nameGap: 30,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: 'category',
        name: dynamicOptions.yName,
        data: dynamicOptions.yData,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisYRotate, margin: 25, interval: 0, ...fontBold },
        nameLocation: 'middle',
        nameGap: 60,
        inverse: true,
        splitLine: {
          show: true,
          lineStyle: {
            type: 'dashed',
          },
        },
        axisLine: {
          show: false,
        },
      },
      series: seriesData,
      toolbox: toolboxDefault,
      brush: {},
      visualMap: bubbleHeatmap
        ? {
            min: 0,
            max: dynamicOptions.visualMax,
            padding: 0,
            calculable: true,
            orient: 'horizontal',
            inverse: true,
            top: 50,
            right: '10%',
            itemWidth: 18,
            itemHeight: 114,
            inRange: {
              color: visualPalette,
            },
            text: ['고', '저'],
          }
        : undefined,
    }
    return options
  }, [data, dynamicOptions, optionValues])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = divRef.current
    if (!element || !chartOptions) return

    let c: echarts.ECharts | null = chartRef.current ?? null

    if (!c) {
      const chart = echarts.init(element, chartThemesConfig[theme])
      chartRef.current = chart
      c = chart
    }

    c.dispose()
    const chart = echarts.init(element, chartThemesConfig[theme])
    chartRef.current = chart
    c = chart

    c.setOption(chartOptions)

    const handleResize = () => {
      c?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartOptions, optionValuesChanged])

  return (
    <div className="flex w-full h-full">
      <div
        ref={divRef}
        className={`flex ${chartHeight} ${widthRatio === 12 ? 'w-full' : `w-${widthRatio}/12`}`}
      ></div>
    </div>
  )
}

export default ScatterChart
