import React from 'react'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

export type BaseSwitchProps = {
  setValue: any
  label: string
  name: any
  value: any
}

function BaseSwitch({ setValue, label, name, value }: BaseSwitchProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.checked)
  }

  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="Item3D" row>
        <FormControlLabel
          control={<Switch color="primary" checked={value} onChange={handleChange} />}
          label={label}
          labelPlacement="start"
          className="mx-0"
        />
      </FormGroup>
    </FormControl>
  )
}

export default BaseSwitch
