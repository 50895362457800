import Toolbar from '@mui/material/Toolbar'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import { styled } from '@mui/material/styles'

import clsx from 'clsx'
import React, { useState, ReactElement } from 'react'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}))

interface ExpandMoreSectionProps {
  title: any
  unfold?: boolean
  children: ReactElement
}

export const ExpandMoreSection = ({ title, unfold = false, children }: ExpandMoreSectionProps) => {
  const [expanded, setExpanded] = useState(unfold)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <Toolbar className="flex flex-row items-center justify-between p-0 h-36 min-h-36 max-h-36 w-full max-w-192">
        <Typography variant="subtitle1" color="inherit">
          {title}
        </Typography>
        <StyledIconButton
          className={clsx('expand', {
            ['expandOpen']: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size="small"
        >
          {expanded ? <ArrowDropDownIcon /> : <ArrowLeftIcon />}
        </StyledIconButton>
      </Toolbar>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  )
}

export default ExpandMoreSection
