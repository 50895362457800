// import { createGenerateClassName, jssPreset, StylesProvider } from '@mui/material/styles'
// import { create } from 'jss'
// import jssExtend from 'jss-plugin-extend'
// import rtl from 'jss-rtl'
import Provider from 'react-redux/es/components/Provider'
import { StyledEngineProvider } from '@mui/material/styles';
import store from './store'

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
      <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <Component {...props} />
          </StyledEngineProvider>
      </Provider>
  )

  return WrapperComponent
}

export default withAppProviders
