import Icon from '@mui/material/Icon'
import Button from '@mui/material/Button'
import MuiAlert from '@mui/material/Alert'
import Tooltip from '@mui/material/Tooltip'
import Snackbar from '@mui/material/Snackbar'
import xlsx from 'xlsx'
import { useState, forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { generateGUID } from 'lib/utils'
import { resetState, setStateFromSheet } from '../../../store/gridSlice'
import HoverMotion from 'components/HoverMotion'

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function UploadBtn() {
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)

  const [wsFile, setWsFile] = useState({ name: '', time: '' })

  function handleChange(e) {
    const file = e.target.files[0]
    if (!file) {
      return
    }

    setWsFile({ name: file.name, time: file.lastModified })

    const reader = new FileReader()

    reader.onload = (ev) => {
      const data = ev.target.result
      const readedData = xlsx.read(data, { type: 'binary' })

      // parse전 바로 넣기
      // dispatch(resetSheetData())
      // dispatch(setSheetData(readedData.Sheets))

      const wsnames = readedData.SheetNames
      let result = { viewName: file.name, allGrids: [] }
      wsnames.map((wsname) => {
        const ws = readedData.Sheets[wsname]
        const dataParse = xlsx.utils.sheet_to_json(ws, { header: 1, blankRows: false, defval: 0 })
        // return result.push({ [wsname]: dataParse })
        return result.allGrids.push({
          id: generateGUID(),
          name: wsname,
          data: dataParse,
          fetchAgin: true,
        })
      })

      dispatch(resetState())
      dispatch(setStateFromSheet(result))

      // 	return console.log(wsname, dataParse);
      // });
      // const dataParse = xlsx.utils.sheet_to_json(ws, { header: 1 });
      // console.log(dataParse);
      // setFileUploaded(dataParse);
    }

    reader.readAsBinaryString(file)

    setOpen(true)

    reader.onerror = () => {
      console.log('error on load file')
    }
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const uploadedFile = !!Object.values(wsFile).some((v) => v !== '') ? wsFile.name : null

  return (
    <div className="flex flex-col items-center space-y-8">
      <label htmlFor="button-file">
        <input
          className="hidden"
          id="button-file"
          type="file"
          // eslint-disable-next-line react/jsx-no-duplicate-props
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onClick={(event) => {
            event.target.value = null
          }}
          onChange={handleChange}
        />
        <HoverMotion>
          <Tooltip title="엑셀 불러오기">
            <Button
              variant="outlined"
              size="small"
              component="span"
              startIcon={<Icon>upload</Icon>}
            >
              엑셀 불러오기
            </Button>
          </Tooltip>
        </HoverMotion>
      </label>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          파일명 [{uploadedFile}] 을/를 불러왔습니다.
        </Alert>
      </Snackbar>
    </div>
  )
}
