import chartThemesConfig from '../../lib/theme/themesConfig'
import materialColors from 'lib/materialColors'

const chartColors = [
  '#ef5350',
  '#ffa726',
  '#66bb6a',
  '#42a5f5',
  '#5c6bc0',
  '#ab47bc',
  '#8d6e63',
  '#78909c',
  '#9ccc65',
  '#7e57c2',
  '#ec407a',
  '#f44336',
  '#ff9800',
  '#4caf50',
  '#2196f3',
  '#3f51b5',
  '#9c27b0',
  '#795548',
  '#607d8b',
  '#8bc34a',
  '#673ab7',
  '#e91e63',
  '#e53935',
  '#fb8c00',
  '#fdd835',
  '#43a047',
  '#1e88e5',
  '#3949ab',
  '#8e24aa',
  '#6d4c41',
  '#546e7a',
  '#7cb342',
  '#5e35b1',
  '#d81b60',
  '#d32f2f',
  '#f57c00',
  '#fbc02d',
  '#388e3c',
  '#1976d2',
  '#303f9f',
  '#7b1fa2',
  '#5d4037',
  '#455a64',
  '#689f38',
  '#512da8',
  '#c2185b',
  '#c62828',
  '#ef6c00',
  '#f9a825',
  '#2e7d32',
  '#1565c0',
  '#283593',
  '#6a1b9a',
  '#4e342e',
  '#37474f',
  '#558b2f',
  '#4527a0',
  '#ad1457',
  '#b71c1c',
  '#e65100',
  '#f57f17',
  '#1b5e20',
  '#0d47a1',
  '#1a237e',
  '#4a148c',
  '#3e2723',
  '#263238',
  '#33691e',
  '#311b92',
  '#880e4f',
  '#e57373',
  '#ffb74d',
  '#fff176',
  '#81c784',
  '#64b5f6',
  '#7986cb',
  '#ba68c8',
  '#a1887f',
  '#90a4ae',
  '#aed581',
  '#9575cd',
  '#f06292',
  '#ef9a9a',
  '#ffcc80',
  '#fff59d',
  '#a5d6a7',
  '#90caf9',
  '#9fa8da',
  '#ce93d8',
  '#bcaaa4',
  '#b0bec5',
  '#c5e1a5',
  '#b39ddb',
  '#f48fb1',
]

export default chartColors

export const toolboxDefault = {
  show: true,
  right: '10%',
  feature: {
    dataZoom: { show: true, title: { zoom: '영역 줌', back: '줌 복원' } },
    dataView: { readOnly: false, title: '표로 보기' },
    magicType: {
      type: ['line', 'bar'],
      title: {
        line: '라인 차트로 바꾸기',
        bar: '바 차트로 바꾸기',
        stack: '스택',
        tile: '타일',
      },
    },
    brush: {
      type: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
      title: {
        rect: '사각형 그리기',
        polygon: '폴리곤 그리기',
        lineX: '라인X 그리기',
        lineY: '라인Y 그리기',
        keep: '유지',
        clear: '초기화',
      },
    },
    saveAsImage: {
      show: true,
      name: 'Techvisor 차트',
      title: '이미지로 저장',
      lang: ['Click to Save'],
    },
  },
}
export const legendDefault = {
  show: true,
  type: 'scroll',
  bottom: 0,
  width: '90%',
  height: '40%',
  orient: 'horizontal',
  textStyle: {
    fontWeight: 'bold',
  },
}
export type chartThemeType = keyof typeof chartThemesConfig
export type ChartsProps = {
  wsData: any
  optionValues: {
    theme: chartThemeType
    item3D: boolean
    widthRatio: number
    heightRatio: number
    gridBottom: number
    gridLeft: number
    pivot: boolean
    ratio: number
    axisX: string
    axisY: string
    axisXRotate: number
    axisYRotate: number
    labelShow: boolean
    labelPosition: string
    bubbleHeatmap: boolean
    colormap: keyof typeof materialColors
    colormapInverse: boolean
    axisXFont: object
    axisYFont: object
    itemFontSize: number
    itemFontWeight: string
    itemFontColor: string
    labelShadowShow: boolean
    labelShadowColor: string
  }
}

export const fontBold = { fontSize: 13, fontFamily: 'Noto Sans KR', fontWeight: 'bold' }
export const fontNormal = { fontSize: 13, fontFamily: 'Noto Sans KR', fontWeight: 'normal' }
export const labelShadow = {
  textShadowBlur: 1.5,
  textShadowOffsetX: 1.0,
  textShadowOffsetY: 1.0,
}
