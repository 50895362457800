import { useState, useEffect } from 'react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import { Switch, Route } from 'react-router-dom'
import Home from 'pages/home'
import About from 'pages/about'
import Contact from 'pages/contact'
import NotFound from 'pages/errors/NotFound'
import VisualToolApp from 'apps/visualTool/VisualToolApp'
import BtwDialog from 'lib/BtwDialog'
import BtwMessage from 'lib/BtwMessage'

function Layout() {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false)
      }
    }

    window.addEventListener('resize', hideMenu)

    return () => {
      window.removeEventListener('resize', hideMenu)
    }
  })

  return (
    <>
      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/visual-tool" component={VisualToolApp} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route component={NotFound} />
      </Switch>
      <div className="flex flex-col flex-auto min-h-0 relative z-10">
        <BtwDialog />
      </div>
      <Footer />
      <BtwMessage />
    </>
  )
}

export default Layout
