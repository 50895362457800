import React from 'react'
import Typography from '@mui/material/Typography'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useUpdateEffect, usePrevious } from '../../@btw/hooks'
import _, { range } from 'lodash'
import BaseSlider from '../BaseSlider'
import BaseSwitch from '../BaseSwitch'
import BaseSelect from '../BaseSelect'
import ExpandMoreSection from '../../ExpandMoreSection'
import ColorPicker from '../../ColorPicker'

export type OptionContainerProps = {
  optionValues: IFormInputs
  setOptionValues: React.Dispatch<React.SetStateAction<any>>
}

interface IFormInputs {
  theme: string
  widthRatio: number
  heightRatio: number
  gridBottom: number
  gridLeft: number
  axisX: string
  axisXRotate: number
  axisY: string
  axisYRotate: number
  ratio: number
  pivot: boolean
  labelShow: boolean
  labelPosition: string
  item3D: boolean
  bubbleHeatmap: boolean
  colormap: string
  colormapInverse: boolean
  itemFontSize: number
  itemFontWeight: string
  itemFontColor: string
  labelShadowShow: boolean
  labelShadowColor: string
}

const schema = yup.object().shape({
  theme: yup.string(),
  widthRatio: yup.number(),
  heightRatio: yup.number(),
  gridBottom: yup.number(),
  gridLeft: yup.number(),
  axisX: yup.string(),
  axisXRotate: yup.number(),
  axisY: yup.string(),
  axisYRotate: yup.number(),
  ratio: yup.number(),
  pivot: yup.bool(),
  labelShow: yup.bool(),
  labelPosition: yup.string(),
  item3D: yup.bool(),
  bubbleHeatMap: yup.bool(),
  colormap: yup.string(),
  colormapInverse: yup.bool(),
  itemFontSize: yup.number(),
  itemFontWeight: yup.string(),
  itemFontColor: yup.string(),
  labelShadowShow: yup.bool(),
  labelShadowColor: yup.string(),
})

function OptionContainer({ optionValues, setOptionValues }: OptionContainerProps) {
  const { handleSubmit, watch, setValue } = useForm<IFormInputs>({
    mode: 'onChange',
    defaultValues: optionValues,
    resolver: yupResolver(schema),
  })

  const form = watch()
  const prevForm = usePrevious(form)
  const formChanged = !_.isEqual(form, prevForm)

  useUpdateEffect(() => {
    if (!prevForm) {
      return
    }

    if (formChanged) {
      onSubmit(form)
    }
  }, [form, prevForm, formChanged])

  const onSubmit = (data: IFormInputs) => setOptionValues(data)

  return (
    <div className="flex w-full h-full">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full space-y-12 pl-16"
      >
        <Typography className="text-gray-500 text-12">공통</Typography>
        <BaseSelect
          name="theme"
          value={form.theme}
          setValue={setValue}
          items={themeList}
          label="테마 선택"
        />
        <BaseSlider
          name="widthRatio"
          value={form.widthRatio}
          setValue={setValue}
          label="넓이 비율"
          min={1}
          max={12}
          step={1}
          marks={true}
        />
        <BaseSlider
          name="heightRatio"
          value={form.heightRatio}
          setValue={setValue}
          label="높이 비율"
          min={1}
          max={10}
          step={1}
          marks={true}
        />
        <BaseSelect
          name="axisX"
          value={form.axisX}
          setValue={setValue}
          items={['category', 'value', 'time', 'log']}
          label="X축 종류"
        />
        <BaseSlider
          name="gridBottom"
          value={form.gridBottom}
          setValue={setValue}
          label="X축 글자 여백"
          min={100}
          max={1000}
          step={50}
          marks={false}
        />
        <BaseSlider
          name="axisXRotate"
          value={form.axisXRotate}
          setValue={setValue}
          label="X축 라벨 회전"
          min={-90}
          max={90}
          step={15}
          marks={true}
        />
        <BaseSelect
          name="axisY"
          value={form.axisY}
          setValue={setValue}
          items={['category', 'value', 'time', 'log']}
          label="Y축 종류"
        />
        <BaseSlider
          name="gridLeft"
          value={form.gridLeft}
          setValue={setValue}
          label="Y축 글자 여백"
          min={100}
          max={1000}
          step={50}
          marks={false}
        />
        <BaseSlider
          name="axisYRotate"
          value={form.axisYRotate}
          setValue={setValue}
          label="Y축 라벨 회전"
          min={-90}
          max={90}
          step={15}
          marks={true}
        />
        <BaseSwitch name="pivot" value={form.pivot} setValue={setValue} label="데이터 회전" />
        <Typography className="text-gray-500 text-12">거품 차트</Typography>
        <BaseSlider
          name="ratio"
          value={form.ratio}
          setValue={setValue}
          label="거품 비율"
          min={1}
          max={20}
          step={1}
          marks={true}
        />
        <BaseSwitch
          name="bubbleHeatmap"
          value={form.bubbleHeatmap}
          setValue={setValue}
          label="거품 히트맵"
        />
        <Typography className="text-gray-500 text-12">거품 차트 · 히트맵</Typography>
        <BaseSelect
          name="colormap"
          value={form.colormap}
          setValue={setValue}
          items={colormapList}
          label="컬러 맵"
        />
        <BaseSwitch
          name="colormapInverse"
          value={form.colormapInverse}
          setValue={setValue}
          label="컬러 맵 반전"
        />
        <ExpandMoreSection title="아이템 라벨 서식">
          <div className="flex flex-col space-y-4">
            <BaseSwitch
              name="labelShow"
              value={form.labelShow}
              setValue={setValue}
              label="라벨 보이기"
            />
            <BaseSwitch name="item3D" value={form.item3D} setValue={setValue} label="아이템 3D" />
            <BaseSelect
              name="labelPosition"
              value={form.labelPosition}
              setValue={setValue}
              items={positionList}
              label="라벨 위치"
            />
            <BaseSelect
              name="itemFontSize"
              value={form.itemFontSize}
              setValue={setValue}
              items={fontSizeList}
              label="글자크기"
            />
            <BaseSelect
              name="itemFontWeight"
              value={form.itemFontWeight}
              setValue={setValue}
              items={fontWeightList}
              label="글자굵기"
            />
            <ColorPicker
              name="itemFontColor"
              value={form.itemFontColor}
              setValue={setValue}
              label="글자색"
            />
            <BaseSwitch
              name="labelShadowShow"
              value={form.labelShadowShow}
              setValue={setValue}
              label="라벨 그림자 넣기"
            />
            <ColorPicker
              name="labelShadowColor"
              value={form.labelShadowColor}
              setValue={setValue}
              label="라벨 그림자색"
            />
          </div>
        </ExpandMoreSection>
      </form>
    </div>
  )
}

export default OptionContainer

const themeList = [
  'basic',
  'chalk',
  'dark',
  'essos',
  'halloween',
  'infographic',
  'macarons',
  'purplePassion',
  'roma',
  'shine',
  'vintage',
  'walden',
  'westeros',
  'wonderland',
]

const positionList = [
  'inside',
  'outside',
  'top',
  'left',
  'right',
  'bottom',
  'insideLeft',
  'insideRight',
  'insideTop',
  'insideBottom',
  'insideTopLeft',
  'insideBottomLeft',
  'insideTopRight',
  'insideBottomRight',
]

const colormapList = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'lime',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
  'brown',
  'grey',
  'blueGrey',
]

const fontWeightList = ['lighter', 'normal', 'bold', 'bolder']
const fontSizeList = range(1, 60)
