import { ECBasicOption } from 'echarts/types/dist/shared'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import echarts from '../../lib/echarts/echarts'
import chartThemesConfig from '../../lib/theme/themesConfig'
import { pieGradient } from '../../lib/echarts/chartUtils'
import { usePrevious } from '../../@btw/hooks'
import { pivot2d, transformData, calcHeight } from '../../lib/echarts/chartUtils'
import materialColors from 'lib/materialColors'
import _ from 'lodash'
import {
  ChartsProps,
  legendDefault,
  toolboxDefault,
  fontBold,
  labelShadow,
} from '../../lib/echarts/chartVariable'

export interface IDynamicOptions {
  xData: any[]
  yData: any[]
  yName: string
  visualMax: number
}

const initialDynamicOptions = {
  xData: [],
  yData: [],
  yName: '',
  visualMax: 0,
}

function HeatmapChart({ wsData, optionValues }: ChartsProps) {
  const chartRef = useRef<echarts.ECharts | null>(null)
  // const muiTheme = useTheme()
  const [data, setData] = useState<any[]>([])
  const [dynamicOptions, setDynamicOptions] = useState(initialDynamicOptions)
  const {
    pivot,
    item3D,
    gridLeft,
    gridBottom,
    axisXRotate,
    axisYRotate,
    widthRatio,
    heightRatio,
    theme,
    labelShow,
    labelPosition,
    colormap,
    colormapInverse,
    itemFontSize,
    itemFontWeight,
    itemFontColor,
    labelShadowShow,
    labelShadowColor,
  } = optionValues

  const prevOptionValues = usePrevious(optionValues)
  const optionValuesChanged = !_.isEqual(optionValues, prevOptionValues)

  useEffect(() => {
    if (wsData.length === 0) return
    const foo = pivot ? pivot2d(wsData) : wsData
    const bar = transformData(foo)
    setData(bar.data)

    const { xData, yData, yName, visualMax } = bar
    setDynamicOptions({ xData, yData, yName, visualMax })
  }, [wsData, pivot])

  const seriesData = useMemo(() => {
    if (data.length === 0) return null

    const colorSet = theme ? chartThemesConfig[theme].color : chartThemesConfig.basic.color

    // const orient = axisX === 'value' && axisY === 'category' ? false : true

    return {
      type: 'heatmap',
      data: data,

      itemStyle: item3D
        ? {
            color: function (params: any) {
              const _color =
                colorSet[params.dataIndex] || colorSet[params.dataIndex % colorSet.length]
              return pieGradient(_color)
            },
          }
        : undefined,
    }
  }, [data, optionValues])

  const chartHeight = useMemo(() => calcHeight(heightRatio), [heightRatio])

  const visualPalette = useMemo(() => {
    const foo = materialColors[colormap]
    const bar = foo ? Object.values(foo) : []
    return colormapInverse ? bar.reverse() : bar
  }, [colormap, colormapInverse])

  const chartOptions = useMemo(() => {
    const labelShadowObj = labelShadowShow ? labelShadow : undefined

    const options: ECBasicOption = {
      tooltip: {
        position: 'top',
      },
      label: {
        show: labelShow,
        position: labelPosition,
        color: itemFontColor ? itemFontColor : 'inherit',
        fontSize: itemFontSize ? itemFontSize : 13,
        fontWeight: itemFontWeight ? itemFontWeight : 'normal',
        ...labelShadowObj,
        textShadowColor: labelShadowColor,
      },
      grid: {
        top: 120,
        left: gridLeft,
        bottom: gridBottom,
      },
      xAxis: {
        type: 'category',
        data: dynamicOptions.xData,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisXRotate, interval: 0, ...fontBold },
      },
      yAxis: {
        type: 'category',
        name: dynamicOptions.yName,
        data: dynamicOptions.yData,
        nameLocation: 'middle',
        nameGap: 60,
        inverse: true,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisYRotate, interval: 0, ...fontBold },
      },
      series: seriesData,
      toolbox: toolboxDefault,
      brush: {},
      legend: legendDefault,
      visualMap: {
        min: 0,
        max: dynamicOptions.visualMax,
        padding: 0,
        calculable: true,
        orient: 'horizontal',
        inverse: true,
        top: 50,
        right: '10%',
        itemWidth: 18,
        itemHeight: 114,
        inRange: {
          color: visualPalette,
        },
        text: ['고', '저'],
      },
    }
    return options
  }, [data, dynamicOptions, optionValues, visualPalette])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = divRef.current
    if (!element || !chartOptions) return

    let c: echarts.ECharts | null = chartRef.current ?? null

    if (!c) {
      const chart = echarts.init(element, chartThemesConfig[theme])
      chartRef.current = chart
      c = chart
    }

    c.dispose()
    const chart = echarts.init(element, chartThemesConfig[theme])
    chartRef.current = chart
    c = chart

    c.setOption(chartOptions)

    const handleResize = () => {
      c?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartOptions, optionValuesChanged])

  return (
    <div className="flex w-full h-full">
      <div
        ref={divRef}
        className={`flex ${chartHeight} ${widthRatio === 12 ? 'w-full' : `w-${widthRatio}/12`}`}
      ></div>
    </div>
  )
}

export default HeatmapChart
