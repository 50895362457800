import { ThemeProvider } from '@mui/material/styles'
import { memo, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectMainTheme } from 'store/btw/settingsSlice'

const useEnhancedEffect = typeof window === 'undefined' ? useEffect : useLayoutEffect

function CustomTheme(props) {
  const direction = useSelector(({ btw }) => btw.settings.current.direction)
  const mainTheme = useSelector(selectMainTheme)

  useEnhancedEffect(() => {
    document.body.dir = direction
  }, [direction])

  // console.warn('CustomTheme:: rendered',mainTheme)
  return <ThemeProvider theme={mainTheme}>{props.children}</ThemeProvider>
}

export default memo(CustomTheme)
