import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import HoverMotion from 'components/HoverMotion'
import { useDispatch } from 'react-redux'
import { saveStoreStateToLocalStorage } from '../../../store/gridSlice'

export default function SaveLocalBtn() {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(saveStoreStateToLocalStorage())
  }

  return (
    <div>
      <HoverMotion>
        <Tooltip title="브라우저에 저장">
          <IconButton onClick={handleClick}>
            <SaveOutlinedIcon />
          </IconButton>
        </Tooltip>
      </HoverMotion>
    </div>
  )
}
