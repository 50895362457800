const themesConfig = {
  basic: {
    color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    backgroundColor: 'rgba(0, 0, 0, 0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#464646',
      },
      subtextStyle: {
        color: '#6E7079',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#eb5454',
        color0: '#47b262',
        borderColor: '#eb5454',
        borderColor0: '#47b262',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaa',
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
      color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
      label: {
        color: '#eee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisLabel: {
        show: true,
        color: '#6E7079',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisLabel: {
        show: true,
        color: '#6E7079',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisLabel: {
        show: true,
        color: '#6E7079',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#6E7079',
        },
      },
      axisLabel: {
        show: true,
        color: '#6E7079',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#E0E6F1'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.2)', 'rgba(210,219,238,0.2)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999',
        },
        emphasis: {
          borderColor: '#666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#ccc',
          width: 1,
        },
        crossStyle: {
          color: '#ccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#DAE1F5',
        width: 2,
      },
      itemStyle: {
        color: '#A4B1D7',
        borderWidth: 1,
        emphasis: {
          color: '#FFF',
        },
      },
      controlStyle: {
        normal: {
          color: '#A4B1D7',
          borderColor: '#A4B1D7',
          borderWidth: 1,
        },
        emphasis: {
          color: '#A4B1D7',
          borderColor: '#A4B1D7',
          borderWidth: 1,
        },
      },
      checkpointStyle: {
        color: '#316bf3',
        borderColor: 'fff',
      },
      label: {
        textStyle: {
          color: '#A4B1D7',
        },
        emphasis: {
          textStyle: {
            color: '#A4B1D7',
          },
        },
      },
    },
    visualMap: {
      color: ['#bf444c', '#d88273', '#f6efa6'],
    },
    dataZoom: {
      handleSize: 'undefined%',
      textStyle: {},
    },
    markPoint: {
      label: {
        color: '#eee',
      },
      emphasis: {
        label: {
          color: '#eee',
        },
      },
    },
  },
  chalk: {
    color: ['#fc97af', '#87f7cf', '#f7f494', '#72ccff', '#f7c5a0', '#d4a4eb', '#d2f5a6', '#76f2f2'],
    backgroundColor: 'rgba(41,52,65,1)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#ffffff',
      },
      subtextStyle: {
        color: '#dddddd',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '4',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '0',
      symbol: 'circle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '4',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '0',
      symbol: 'circle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#fc97af',
        color0: 'transparent',
        borderColor: '#fc97af',
        borderColor0: '#87f7cf',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: '1',
        color: '#ffffff',
      },
      symbolSize: '0',
      symbol: 'circle',
      smooth: true,
      color: ['#fc97af', '#87f7cf', '#f7f494', '#72ccff', '#f7c5a0', '#d4a4eb', '#d2f5a6', '#76f2f2'],
      label: {
        color: '#293441',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#893448',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(137,52,72)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,178,72,1)',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#893448',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(137,52,72)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#aaaaaa',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#87f7cf',
        width: 1,
      },
      itemStyle: {
        color: '#87f7cf',
        borderWidth: 1,
        emphasis: {
          color: '#f7f494',
        },
      },
      controlStyle: {
        normal: {
          color: '#87f7cf',
          borderColor: '#87f7cf',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#87f7cf',
          borderColor: '#87f7cf',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#fc97af',
        borderColor: '#fc97af',
      },
      label: {
        textStyle: {
          color: '#87f7cf',
        },
        emphasis: {
          textStyle: {
            color: '#87f7cf',
          },
        },
      },
    },
    visualMap: {
      color: ['#fc97af', '#87f7cf'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(114,204,255,1)',
      fillerColor: 'rgba(114,204,255,0.2)',
      handleColor: '#72ccff',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#293441',
      },
      emphasis: {
        label: {
          color: '#293441',
        },
      },
    },
  },
  dark: {
    color: [
      '#dd6b66',
      '#759aa0',
      '#e69d87',
      '#8dc1a9',
      '#ea7e53',
      '#eedd78',
      '#73a373',
      '#73b9bc',
      '#7289ab',
      '#91ca8c',
      '#f49f42',
    ],
    backgroundColor: 'rgba(51,51,51,1)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#eeeeee',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'circle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'circle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#fd1050',
        color0: '#0cf49b',
        borderColor: '#fd1050',
        borderColor0: '#0cf49b',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: 4,
      symbol: 'circle',
      smooth: false,
      color: [
        '#dd6b66',
        '#759aa0',
        '#e69d87',
        '#8dc1a9',
        '#ea7e53',
        '#eedd78',
        '#73a373',
        '#73b9bc',
        '#7289ab',
        '#91ca8c',
        '#f49f42',
      ],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisLabel: {
        show: true,
        color: '#eeeeee',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#aaaaaa'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['#eeeeee'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisLabel: {
        show: true,
        color: '#eeeeee',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#aaaaaa'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['#eeeeee'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisLabel: {
        show: true,
        color: '#eeeeee',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#aaaaaa'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['#eeeeee'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#eeeeee',
        },
      },
      axisLabel: {
        show: true,
        color: '#eeeeee',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#aaaaaa'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['#eeeeee'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#eeeeee',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#eeeeee',
          width: '1',
        },
        crossStyle: {
          color: '#eeeeee',
          width: '1',
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#eeeeee',
        width: 1,
      },
      itemStyle: {
        color: '#dd6b66',
        borderWidth: 1,
        emphasis: {
          color: '#a9334c',
        },
      },
      controlStyle: {
        normal: {
          color: '#eeeeee',
          borderColor: '#eeeeee',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#eeeeee',
          borderColor: '#eeeeee',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#e43c59',
        borderColor: '#c23531',
      },
      label: {
        textStyle: {
          color: '#eeeeee',
        },
        emphasis: {
          textStyle: {
            color: '#eeeeee',
          },
        },
      },
    },
    visualMap: {
      color: ['#bf444c', '#d88273', '#f6efa6'],
    },
    dataZoom: {
      backgroundColor: 'rgba(47,69,84,0)',
      dataBackgroundColor: 'rgba(255,255,255,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#eeeeee',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  essos: {
    color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
    backgroundColor: 'rgba(242,234,191,0.15)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#893448',
      },
      subtextStyle: {
        color: '#d95850',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#eb8146',
        color0: 'transparent',
        borderColor: '#d95850',
        borderColor0: '#58c470',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
      color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', '#ebdba4'],
      label: {
        color: '#ffffff',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#ffb248',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#893448',
        },
        emphasis: {
          textStyle: {
            color: '#893448',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#ffb248',
          borderColor: '#eb8146',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#893448',
        },
        emphasis: {
          textStyle: {
            color: '#893448',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#aaaaaa',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#aaaaaa',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#aaaaaa',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#aaaaaa',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#e6e6e6'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#893448',
        width: 1,
      },
      itemStyle: {
        color: '#893448',
        borderWidth: 1,
        emphasis: {
          color: '#ffb248',
        },
      },
      controlStyle: {
        normal: {
          color: '#893448',
          borderColor: '#893448',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#893448',
          borderColor: '#893448',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#eb8146',
        borderColor: '#ffb248',
      },
      label: {
        textStyle: {
          color: '#893448',
        },
        emphasis: {
          textStyle: {
            color: '#893448',
          },
        },
      },
    },
    visualMap: {
      color: ['#893448', '#d95850', '#eb8146', '#ffb248', '#f2d643', 'rgb(247,238,173)'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(255,178,72,0.5)',
      fillerColor: 'rgba(255,178,72,0.15)',
      handleColor: '#ffb248',
      handleSize: '100%',
      textStyle: {
        color: '#333',
      },
    },
    markPoint: {
      label: {
        color: '#ffffff',
      },
      emphasis: {
        label: {
          color: '#ffffff',
        },
      },
    },
  },
  halloween: {
    color: ['#ff715e', '#ffaf51', '#ffee51', '#8c6ac4', '#715c87'],
    backgroundColor: 'rgba(64,64,64,0.5)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#ffaf51',
      },
      subtextStyle: {
        color: '#eeeeee',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: '0',
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    scatter: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    boxplot: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    parallel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    sankey: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    funnel: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    gauge: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
    },
    candlestick: {
      itemStyle: {
        color: '#ffee51',
        color0: '#ffffff',
        borderColor: '#ff715e',
        borderColor0: '#797fba',
        borderWidth: '1',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: '0',
        borderColor: '#ccc',
      },
      lineStyle: {
        width: '1',
        color: '#888888',
      },
      symbolSize: '8',
      symbol:
        'path://M237.062,81.761L237.062,81.761c-12.144-14.24-25.701-20.1-40.68-19.072 c-10.843,0.747-20.938,5.154-30.257,13.127c-9.51-5.843-19.8-9.227-30.859-10.366c0.521-3.197,1.46-6.306,2.85-9.363 c3.458-7.038,8.907-12.741,16.331-17.296c-5.609-3.384-11.227-6.799-16.854-10.279c-16.257,8.104-25.06,20.601-26.463,38.417 c-7.599,1.705-14.685,4.486-21.247,8.437c-9.164-7.677-18.996-11.917-29.496-12.632c-14.819-0.998-28.467,4.787-40.938,18.827 C6.445,96.182,0,114.867,0,136.242c-0.007,6.371,0.674,12.646,2.053,18.738c4.593,22.785,15.398,41.367,32.558,55.344 c15.43,12.773,29.901,18.023,43.362,16.981c7.074-0.561,13.624-3.977,19.685-10.192c10.534,5.49,20.391,8.217,29.561,8.203 c9.856-0.012,20.236-2.953,31.125-8.898c6.227,6.692,12.966,10.346,20.211,10.933c13.795,1.073,28.614-4.111,44.377-16.84 c17.49-14.104,28.043-32.79,31.796-55.485c0.836-5.624,1.272-11.292,1.272-16.966C255.998,115.814,249.707,96.601,237.062,81.761z  M54.795,97.7l40.661,14.496c-4.402,8.811-10.766,13.219-19.06,13.219c-2.542,0-4.917-0.419-7.122-1.274 C58.103,118.38,53.263,109.572,54.795,97.7z M150.613,185.396l-9.156-8.389l-7.619,12.951c-3.391,0.341-6.615,0.514-9.665,0.514 c-4.401,0-8.635-0.263-12.708-0.777l-8.634-14.973l-9.151,9.909c-4.91-2.717-9.15-5.856-12.708-9.413 c-8.81-8.295-13.384-17.959-13.727-28.97c2.877,1.692,7.427,3.461,13.675,5.308l10.636,13.629l9.44-9.852 c4.734,0.702,9.234,1.12,13.466,1.275l10.689,11.498l9.671-11.949c3.559-0.173,7.285-0.515,11.182-1.01l9.924,10.159l10.933-14.227 c5.931-1.351,11.196-2.798,15.771-4.323C179.747,163.538,169.068,176.414,150.613,185.396z M175.258,124.907 c-2.209,0.849-4.66,1.273-7.369,1.273c-8.134,0-14.489-4.415-19.052-13.224l40.905-14.477 C191.105,110.331,186.273,119.141,175.258,124.907z',
      smooth: false,
      color: ['#ff715e', '#ffaf51', '#ffee51', '#8c6ac4', '#715c87'],
      label: {
        color: '#333333',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#555555',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,175,81,0.5)',
          borderColor: '#ffaf51',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#ffffff',
        },
        emphasis: {
          textStyle: {
            color: '#ffee51',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#555555',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,175,81,0.5)',
          borderColor: '#ffaf51',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#ffffff',
        },
        emphasis: {
          textStyle: {
            color: '#ffee51',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#555555'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#555555'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#555555'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#666666',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#555555'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#ffaf51',
        width: 1,
      },
      itemStyle: {
        color: '#ffaf51',
        borderWidth: 1,
        emphasis: {
          color: '#ffaf51',
        },
      },
      controlStyle: {
        normal: {
          color: '#ffaf51',
          borderColor: '#ffaf51',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#ffaf51',
          borderColor: '#ffaf51',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#ff715e',
        borderColor: '#ff715e',
      },
      label: {
        textStyle: {
          color: '#ff715e',
        },
        emphasis: {
          textStyle: {
            color: '#ff715e',
          },
        },
      },
    },
    visualMap: {
      color: ['#ff715e', '#ffee51', '#797fba'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(222,222,222,1)',
      fillerColor: 'rgba(255,113,94,0.2)',
      handleColor: '#cccccc',
      handleSize: '100%',
      textStyle: {
        color: '#999999',
      },
    },
    markPoint: {
      label: {
        color: '#333333',
      },
      emphasis: {
        label: {
          color: '#333333',
        },
      },
    },
  },
  infographic: {
    color: [
      '#c1232b',
      '#27727b',
      '#fcce10',
      '#e87c25',
      '#b5c334',
      '#fe8463',
      '#9bca63',
      '#fad860',
      '#f3a43b',
      '#60c0dd',
      '#d7504b',
      '#c6e579',
      '#f4e001',
      '#f0805a',
      '#26c0c0',
    ],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#27727b',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#c1232b',
        color0: '#b5c334',
        borderColor: '#c1232b',
        borderColor0: '#b5c334',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: '5',
      symbol: 'emptyCircle',
      smooth: false,
      color: [
        '#c1232b',
        '#27727b',
        '#fcce10',
        '#e87c25',
        '#b5c334',
        '#fe8463',
        '#9bca63',
        '#fad860',
        '#f3a43b',
        '#60c0dd',
        '#d7504b',
        '#c6e579',
        '#f4e001',
        '#f0805a',
        '#26c0c0',
      ],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#dddddd',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#fe994e',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#c1232b',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#dddddd',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#fe994e',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#c1232b',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#27727b',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#27727b',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#27727b',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#27727b',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#c1232b',
        },
        emphasis: {
          borderColor: '#e87c25',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#27727b',
          width: 1,
        },
        crossStyle: {
          color: '#27727b',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#293c55',
        width: 1,
      },
      itemStyle: {
        color: '#27727b',
        borderWidth: 1,
        emphasis: {
          color: '#72d4e0',
        },
      },
      controlStyle: {
        normal: {
          color: '#27727b',
          borderColor: '#27727b',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#27727b',
          borderColor: '#27727b',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#c1232b',
        borderColor: '#c23531',
      },
      label: {
        textStyle: {
          color: '#293c55',
        },
        emphasis: {
          textStyle: {
            color: '#293c55',
          },
        },
      },
    },
    visualMap: {
      color: ['#c1232b', '#fcce10'],
    },
    dataZoom: {
      backgroundColor: 'rgba(0,0,0,0)',
      dataBackgroundColor: 'rgba(181,195,52,0.3)',
      fillerColor: 'rgba(181,195,52,0.2)',
      handleColor: '#27727b',
      handleSize: '100%',
      textStyle: {
        color: '#999999',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  macarons: {
    color: [
      '#2ec7c9',
      '#b6a2de',
      '#5ab1ef',
      '#ffb980',
      '#d87a80',
      '#8d98b3',
      '#e5cf0d',
      '#97b552',
      '#95706d',
      '#dc69aa',
      '#07a2a4',
      '#9a7fd1',
      '#588dd5',
      '#f5994e',
      '#c05050',
      '#59678c',
      '#c9ab00',
      '#7eb00a',
      '#6f5553',
      '#c14089',
    ],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#008acd',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 3,
      symbol: 'emptyCircle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 3,
      symbol: 'emptyCircle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#d87a80',
        color0: '#2ec7c9',
        borderColor: '#d87a80',
        borderColor0: '#2ec7c9',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: 3,
      symbol: 'emptyCircle',
      smooth: true,
      color: [
        '#2ec7c9',
        '#b6a2de',
        '#5ab1ef',
        '#ffb980',
        '#d87a80',
        '#8d98b3',
        '#e5cf0d',
        '#97b552',
        '#95706d',
        '#dc69aa',
        '#07a2a4',
        '#9a7fd1',
        '#588dd5',
        '#f5994e',
        '#c05050',
        '#59678c',
        '#c9ab00',
        '#7eb00a',
        '#6f5553',
        '#c14089',
      ],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#dddddd',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(254,153,78,1)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#d87a80',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#dddddd',
        borderColor: '#eeeeee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(254,153,78,1)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#d87a80',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#008acd',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#eee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#008acd',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#008acd',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#008acd',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#2ec7c9',
        },
        emphasis: {
          borderColor: '#18a4a6',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#008acd',
          width: '1',
        },
        crossStyle: {
          color: '#008acd',
          width: '1',
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#008acd',
        width: 1,
      },
      itemStyle: {
        color: '#008acd',
        borderWidth: 1,
        emphasis: {
          color: '#a9334c',
        },
      },
      controlStyle: {
        normal: {
          color: '#008acd',
          borderColor: '#008acd',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#008acd',
          borderColor: '#008acd',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#2ec7c9',
        borderColor: '#2ec7c9',
      },
      label: {
        textStyle: {
          color: '#008acd',
        },
        emphasis: {
          textStyle: {
            color: '#008acd',
          },
        },
      },
    },
    visualMap: {
      color: ['#5ab1ef', '#e0ffff'],
    },
    dataZoom: {
      backgroundColor: 'rgba(47,69,84,0)',
      dataBackgroundColor: '#efefff',
      fillerColor: 'rgba(182,162,222,0.2)',
      handleColor: '#008acd',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  purplePassion: {
    color: ['#9b8bba', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
    backgroundColor: 'rgba(91,92,110,1)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#ffffff',
      },
      subtextStyle: {
        color: '#cccccc',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '7',
      symbol: 'circle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '7',
      symbol: 'circle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#e098c7',
        color0: 'transparent',
        borderColor: '#e098c7',
        borderColor0: '#8fd3e8',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: '7',
      symbol: 'circle',
      smooth: true,
      color: ['#9b8bba', '#e098c7', '#8fd3e8', '#71669e', '#cc70af', '#7cb4cc'],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#e098c7',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: '#ffffff',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eee',
        borderColor: '#444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#e098c7',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: '#ffffff',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#cccccc',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#eeeeee', '#333333'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#cccccc',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#eeeeee', '#333333'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#cccccc',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#eeeeee', '#333333'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#cccccc',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#eeeeee', '#333333'],
        },
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#cccccc',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#8fd3e8',
        width: 1,
      },
      itemStyle: {
        color: '#8fd3e8',
        borderWidth: 1,
        emphasis: {
          color: '#8fd3e8',
        },
      },
      controlStyle: {
        normal: {
          color: '#8fd3e8',
          borderColor: '#8fd3e8',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#8fd3e8',
          borderColor: '#8fd3e8',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#8fd3e8',
        borderColor: '#8a7ca8',
      },
      label: {
        textStyle: {
          color: '#8fd3e8',
        },
        emphasis: {
          textStyle: {
            color: '#8fd3e8',
          },
        },
      },
    },
    visualMap: {
      color: ['#8a7ca8', '#e098c7', '#cceffa'],
    },
    dataZoom: {
      backgroundColor: 'rgba(0,0,0,0)',
      dataBackgroundColor: 'rgba(255,255,255,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  roma: {
    color: [
      '#e01f54',
      '#001852',
      '#f5e8c8',
      '#b8d2c7',
      '#c6b38e',
      '#a4d8c2',
      '#f3d999',
      '#d3758f',
      '#dcc392',
      '#2e4783',
      '#82b6e9',
      '#ff6347',
      '#a092f1',
      '#0a915d',
      '#eaf889',
      '#6699FF',
      '#ff6666',
      '#3cb371',
      '#d5b158',
      '#38b6b6',
    ],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#333333',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#e01f54',
        color0: '#001852',
        borderColor: '#f5e8c8',
        borderColor0: '#b8d2c7',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
      color: [
        '#e01f54',
        '#001852',
        '#f5e8c8',
        '#b8d2c7',
        '#c6b38e',
        '#a4d8c2',
        '#f3d999',
        '#d3758f',
        '#dcc392',
        '#2e4783',
        '#82b6e9',
        '#ff6347',
        '#a092f1',
        '#0a915d',
        '#eaf889',
        '#6699FF',
        '#ff6666',
        '#3cb371',
        '#d5b158',
        '#38b6b6',
      ],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#293c55',
        width: 1,
      },
      itemStyle: {
        color: '#293c55',
        borderWidth: 1,
        emphasis: {
          color: '#a9334c',
        },
      },
      controlStyle: {
        normal: {
          color: '#293c55',
          borderColor: '#293c55',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#293c55',
          borderColor: '#293c55',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#e43c59',
        borderColor: '#c23531',
      },
      label: {
        textStyle: {
          color: '#293c55',
        },
        emphasis: {
          textStyle: {
            color: '#293c55',
          },
        },
      },
    },
    visualMap: {
      color: ['#e01f54', '#e7dbc3'],
    },
    dataZoom: {
      backgroundColor: 'rgba(47,69,84,0)',
      dataBackgroundColor: 'rgba(47,69,84,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  shine: {
    color: ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#333333',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#c12e34',
        color0: '#2b821d',
        borderColor: '#c12e34',
        borderColor0: '#2b821d',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
      color: ['#c12e34', '#e6b600', '#0098d9', '#2b821d', '#005eaa', '#339ca8', '#cda819', '#32a487'],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#ddd',
        borderColor: '#eee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#e6b600',
          borderColor: '#ddd',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#c12e34',
        },
        emphasis: {
          textStyle: {
            color: '#c12e34',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#ddd',
        borderColor: '#eee',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#e6b600',
          borderColor: '#ddd',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#c12e34',
        },
        emphasis: {
          textStyle: {
            color: '#c12e34',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#06467c',
        },
        emphasis: {
          borderColor: '#4187c2',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#005eaa',
        width: 1,
      },
      itemStyle: {
        color: '#005eaa',
        borderWidth: 1,
        emphasis: {
          color: '#005eaa',
        },
      },
      controlStyle: {
        normal: {
          color: '#005eaa',
          borderColor: '#005eaa',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#005eaa',
          borderColor: '#005eaa',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#005eaa',
        borderColor: '#316bc2',
      },
      label: {
        textStyle: {
          color: '#005eaa',
        },
        emphasis: {
          textStyle: {
            color: '#005eaa',
          },
        },
      },
    },
    visualMap: {
      color: ['#1790cf', '#a2d4e6'],
    },
    dataZoom: {
      backgroundColor: 'rgba(47,69,84,0)',
      dataBackgroundColor: 'rgba(47,69,84,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  vintage: {
    color: [
      '#d87c7c',
      '#919e8b',
      '#d7ab82',
      '#6e7074',
      '#61a0a8',
      '#efa18d',
      '#787464',
      '#cc7e63',
      '#724e58',
      '#4b565b',
    ],
    backgroundColor: 'rgba(254,248,239,1)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#333333',
      },
      subtextStyle: {
        color: '#aaaaaa',
      },
    },
    line: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: 1,
      },
      lineStyle: {
        width: 2,
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#c23531',
        color0: '#314656',
        borderColor: '#c23531',
        borderColor0: '#314656',
        borderWidth: 1,
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: 4,
      symbol: 'emptyCircle',
      smooth: false,
      color: [
        '#d87c7c',
        '#919e8b',
        '#d7ab82',
        '#6e7074',
        '#61a0a8',
        '#efa18d',
        '#787464',
        '#cc7e63',
        '#724e58',
        '#4b565b',
      ],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#444444',
        borderWidth: 0.5,
      },
      emphasis: {
        itemStyle: {
          areaColor: 'rgba(255,215,0,0.8)',
          borderColor: '#444444',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000000',
        },
        emphasis: {
          textStyle: {
            color: 'rgb(100,0,0)',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisTick: {
        show: true,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#333',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#ccc'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#333333',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#293c55',
        width: 1,
      },
      itemStyle: {
        color: '#293c55',
        borderWidth: 1,
        emphasis: {
          color: '#a9334c',
        },
      },
      controlStyle: {
        normal: {
          color: '#293c55',
          borderColor: '#293c55',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#293c55',
          borderColor: '#293c55',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#e43c59',
        borderColor: '#c23531',
      },
      label: {
        textStyle: {
          color: '#293c55',
        },
        emphasis: {
          textStyle: {
            color: '#293c55',
          },
        },
      },
    },
    visualMap: {
      color: ['#bf444c', '#d88273', '#f6efa6'],
    },
    dataZoom: {
      backgroundColor: 'rgba(47,69,84,0)',
      dataBackgroundColor: 'rgba(47,69,84,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  walden: {
    color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
    backgroundColor: 'rgba(252,252,252,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#666666',
      },
      subtextStyle: {
        color: '#999999',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#e6a0d2',
        color0: 'transparent',
        borderColor: '#e6a0d2',
        borderColor0: '#3fb1e3',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: '1',
        color: '#cccccc',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
      color: ['#3fb1e3', '#6be6c1', '#626c91', '#a0a7e6', '#c4ebad', '#96dee8'],
      label: {
        color: '#ffffff',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#aaaaaa',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(63,177,227,0.25)',
          borderColor: '#3fb1e3',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#ffffff',
        },
        emphasis: {
          textStyle: {
            color: '#3fb1e3',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#aaaaaa',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(63,177,227,0.25)',
          borderColor: '#3fb1e3',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#ffffff',
        },
        emphasis: {
          textStyle: {
            color: '#3fb1e3',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#626c91',
        width: 1,
      },
      itemStyle: {
        color: '#626c91',
        borderWidth: 1,
        emphasis: {
          color: '#626c91',
        },
      },
      controlStyle: {
        normal: {
          color: '#626c91',
          borderColor: '#626c91',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#626c91',
          borderColor: '#626c91',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#3fb1e3',
        borderColor: '#3fb1e3',
      },
      label: {
        textStyle: {
          color: '#626c91',
        },
        emphasis: {
          textStyle: {
            color: '#626c91',
          },
        },
      },
    },
    visualMap: {
      color: ['#2a99c9', '#afe8ff'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(222,222,222,1)',
      fillerColor: 'rgba(114,230,212,0.25)',
      handleColor: '#cccccc',
      handleSize: '100%',
      textStyle: {
        color: '#999999',
      },
    },
    markPoint: {
      label: {
        color: '#ffffff',
      },
      emphasis: {
        label: {
          color: '#ffffff',
        },
      },
    },
  },
  westeros: {
    color: ['#516b91', '#59c4e6', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3'],
    backgroundColor: 'rgba(0,0,0,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#516b91',
      },
      subtextStyle: {
        color: '#93b7e3',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '2',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#edafda',
        color0: 'transparent',
        borderColor: '#d680bc',
        borderColor0: '#8fd3e8',
        borderWidth: '2',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: 1,
        color: '#aaaaaa',
      },
      symbolSize: '6',
      symbol: 'emptyCircle',
      smooth: true,
      color: ['#516b91', '#59c4e6', '#edafda', '#93b7e3', '#a5e7f0', '#cbb0e3'],
      label: {
        color: '#eeeeee',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#516b91',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#a5e7f0',
          borderColor: '#516b91',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: '#516b91',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#f3f3f3',
        borderColor: '#516b91',
        borderWidth: 0.5,
        emphasis: {
          areaColor: '#a5e7f0',
          borderColor: '#516b91',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#000',
        },
        emphasis: {
          textStyle: {
            color: '#516b91',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#8fd3e8',
        width: 1,
      },
      itemStyle: {
        color: '#8fd3e8',
        borderWidth: 1,
        emphasis: {
          color: '#8fd3e8',
        },
      },
      controlStyle: {
        normal: {
          color: '#8fd3e8',
          borderColor: '#8fd3e8',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#8fd3e8',
          borderColor: '#8fd3e8',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#8fd3e8',
        borderColor: '#8a7ca8',
      },
      label: {
        textStyle: {
          color: '#8fd3e8',
        },
        emphasis: {
          textStyle: {
            color: '#8fd3e8',
          },
        },
      },
    },
    visualMap: {
      color: ['#516b91', '#59c4e6', '#a5e7f0'],
    },
    dataZoom: {
      backgroundColor: 'rgba(0,0,0,0)',
      dataBackgroundColor: 'rgba(255,255,255,0.3)',
      fillerColor: 'rgba(167,183,204,0.4)',
      handleColor: '#a7b7cc',
      handleSize: '100%',
      textStyle: {
        color: '#333',
      },
    },
    markPoint: {
      label: {
        color: '#eeeeee',
      },
      emphasis: {
        label: {
          color: '#eeeeee',
        },
      },
    },
  },
  wonderland: {
    color: ['#4ea397', '#22c3aa', '#7bd9a5', '#d0648a', '#f58db2', '#f2b3c9'],
    backgroundColor: 'rgba(255,255,255,0)',
    textStyle: {},
    title: {
      textStyle: {
        color: '#666666',
      },
      subtextStyle: {
        color: '#999999',
      },
    },
    line: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
    },
    radar: {
      itemStyle: {
        borderWidth: '2',
      },
      lineStyle: {
        width: '3',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
    pie: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    scatter: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    boxplot: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    parallel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    sankey: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    funnel: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    gauge: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
    },
    candlestick: {
      itemStyle: {
        color: '#d0648a',
        color0: 'transparent',
        borderColor: '#d0648a',
        borderColor0: '#22c3aa',
        borderWidth: '1',
      },
    },
    graph: {
      itemStyle: {
        borderWidth: 0,
        borderColor: '#ccc',
      },
      lineStyle: {
        width: '1',
        color: '#cccccc',
      },
      symbolSize: '8',
      symbol: 'emptyCircle',
      smooth: false,
      color: ['#4ea397', '#22c3aa', '#7bd9a5', '#d0648a', '#f58db2', '#f2b3c9'],
      label: {
        color: '#ffffff',
      },
    },
    map: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(34,195,170,0.25)',
          borderColor: '#22c3aa',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#28544e',
        },
        emphasis: {
          textStyle: {
            color: '#349e8e',
          },
        },
      },
    },
    geo: {
      itemStyle: {
        areaColor: '#eeeeee',
        borderColor: '#999999',
        borderWidth: 0.5,
        emphasis: {
          areaColor: 'rgba(34,195,170,0.25)',
          borderColor: '#22c3aa',
          borderWidth: 1,
        },
      },
      label: {
        textStyle: {
          color: '#28544e',
        },
        emphasis: {
          textStyle: {
            color: '#349e8e',
          },
        },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: {
          color: '#cccccc',
        },
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#333',
        },
      },
      axisLabel: {
        show: true,
        color: '#999999',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: ['#eeeeee'],
        },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
        },
      },
    },
    toolbox: {
      iconStyle: {
        normal: {
          borderColor: '#999999',
        },
        emphasis: {
          borderColor: '#666666',
        },
      },
    },
    legend: {
      textStyle: {
        color: '#999999',
      },
    },
    tooltip: {
      axisPointer: {
        lineStyle: {
          color: '#cccccc',
          width: 1,
        },
        crossStyle: {
          color: '#cccccc',
          width: 1,
        },
      },
    },
    timeline: {
      lineStyle: {
        color: '#4ea397',
        width: 1,
      },
      itemStyle: {
        color: '#4ea397',
        borderWidth: 1,
        emphasis: {
          color: '#4ea397',
        },
      },
      controlStyle: {
        normal: {
          color: '#4ea397',
          borderColor: '#4ea397',
          borderWidth: 0.5,
        },
        emphasis: {
          color: '#4ea397',
          borderColor: '#4ea397',
          borderWidth: 0.5,
        },
      },
      checkpointStyle: {
        color: '#4ea397',
        borderColor: '#3cebd2',
      },
      label: {
        textStyle: {
          color: '#4ea397',
        },
        emphasis: {
          textStyle: {
            color: '#4ea397',
          },
        },
      },
    },
    visualMap: {
      color: ['#d0648a', '#22c3aa', '#adfff1'],
    },
    dataZoom: {
      backgroundColor: 'rgba(255,255,255,0)',
      dataBackgroundColor: 'rgba(222,222,222,1)',
      fillerColor: 'rgba(114,230,212,0.25)',
      handleColor: '#cccccc',
      handleSize: '100%',
      textStyle: {
        color: '#999999',
      },
    },
    markPoint: {
      label: {
        color: '#ffffff',
      },
      emphasis: {
        label: {
          color: '#ffffff',
        },
      },
    },
  },
}

export default themesConfig
