import { ECBasicOption } from 'echarts/types/dist/shared'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import echarts from '../../lib/echarts/echarts'
import chartThemesConfig from '../../lib/theme/themesConfig'
import { usePrevious } from '../../@btw/hooks'
import _ from 'lodash'
import { pieGradient } from '../../lib/echarts/chartUtils'
import { pivot2d, transformData, calcHeight } from '../../lib/echarts/chartUtils'
import {
  ChartsProps,
  legendDefault,
  toolboxDefault,
  fontNormal,
  labelShadow,
} from '../../lib/echarts/chartVariable'

function PieChart({ wsData, optionValues }: ChartsProps) {
  const chartRef = useRef<echarts.ECharts | null>(null)

  const [data, setData] = useState<any[]>([])

  const {
    pivot,
    item3D,
    gridLeft,
    gridBottom,
    widthRatio,
    heightRatio,
    theme,
    labelShow,
    labelPosition,
    itemFontSize,
    itemFontWeight,
    itemFontColor,
    labelShadowShow,
    labelShadowColor,
  } = optionValues

  const prevOptionValues = usePrevious(optionValues)
  const optionValuesChanged = !_.isEqual(optionValues, prevOptionValues)

  useEffect(() => {
    if (wsData.length === 0) return
    const bar = pivot ? pivot2d(wsData) : wsData

    setData(bar)
  }, [wsData, pivot])

  const seriesData = useMemo(() => {
    if (data.length === 0) return null

    const colorSet = theme ? chartThemesConfig[theme].color : chartThemesConfig.basic.color

    const labelShadowObj = labelShadowShow ? labelShadow : undefined

    return {
      type: 'pie',
      itemStyle: item3D
        ? {
            color: function (params: any) {
              const _color =
                colorSet[params.dataIndex] || colorSet[params.dataIndex % colorSet.length]
              return pieGradient(_color)
            },
          }
        : undefined,
      radius: '80%',
      // radius: ['40%', '80%'],
      center: ['50%', '50%'],
      label: {
        show: labelShow,
        position: labelPosition,
        alignTo: 'edge',
        formatter: '{num|{c}} {per|{d}%}',
        minMargin: 5,
        edgeDistance: 10,
        lineHeight: 15,
        rich: {
          num: {
            color: itemFontColor ? itemFontColor : 'inherit',
            fontSize: itemFontSize ? itemFontSize : 13,
            fontWeight: itemFontWeight ? itemFontWeight : 'normal',
            ...labelShadowObj,
            textShadowColor: labelShadowColor,
          },
          per: {
            ...fontNormal,

            // color: '#fff',
            // backgroundColor: '#4C5058',
            padding: [3, 4],
            borderRadius: 4,
          },
        },
      },
      labelLine: {
        length: 30,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }
  }, [data, optionValues])

  const chartHeight = useMemo(() => calcHeight(heightRatio), [heightRatio])

  const chartOptions = useMemo(() => {
    const options: ECBasicOption = {
      tooltip: {
        trigger: 'item',
      },
      grid: {
        left: gridLeft,
        bottom: gridBottom,
      },
      dataset: {
        source: data,
      },
      series: seriesData,
      toolbox: toolboxDefault,
      brush: {},
      legend: legendDefault,
    }
    return options
  }, [data, optionValues])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = divRef.current
    if (!element || !chartOptions) return

    let c: echarts.ECharts | null = chartRef.current ?? null

    if (!c) {
      const chart = echarts.init(element, chartThemesConfig[theme])
      chartRef.current = chart
      c = chart
    }

    c.dispose()
    const chart = echarts.init(element, chartThemesConfig[theme])
    chartRef.current = chart
    c = chart

    c.setOption(chartOptions)

    const handleResize = () => {
      c?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartOptions, optionValues, optionValuesChanged])

  return (
    <div className="flex w-full h-full">
      <div
        ref={divRef}
        className={`flex ${chartHeight} ${widthRatio === 12 ? 'w-full' : `w-${widthRatio}/12`}`}
      ></div>
    </div>
  )
}

export default PieChart
