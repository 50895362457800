import SaveLocalBtn from '../SaveLocalBtn'
import DeleteLocalBtn from '../DeleteLocalBtn'
import AddViewBtn from '../AddViewBtn'
import AddTabBtn from '../AddTabBtn'
import UploadBtn from '../UploadBtn'

export default function ActionButtons() {
  return (
    <div className="flex flex-col justify-start space-y-16">
      <div className="flex justify-start">
        <UploadBtn />
      </div>
      <div className="flex flex-row items-center justify-start space-x-8">
        <AddViewBtn />
        <AddTabBtn />
        <div className="flex flex-row">
          <SaveLocalBtn />
          <DeleteLocalBtn />
        </div>
      </div>
    </div>
  )
}
