import * as React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import NavListItem from './NavListItem'

type NavListProps = {
  navigation: any[]
  selectedId: number
  onItemClick: React.EventHandler<any>
}

export default function NavList(props: NavListProps) {
  const { navigation, selectedId, onItemClick } = props

  function handleItemClick(item: object) {
    onItemClick?.(item)
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="secondary mailbox folder">
        {navigation.map((_item: any, index: number) => (
          <NavListItem
            key={_item.id}
            item={_item}
            selected={!!(selectedId === index)}
            onItemClick={handleItemClick}
          />
        ))}
      </List>
    </Box>
  )
}
