import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DialogContentText from '@mui/material/DialogContentText'
import ListItemButton from '@mui/material/ListItemButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import Input from '@mui/material/Input'
import { useDispatch } from 'react-redux'
import { useMemo, useState } from 'react'
import { closeDialog, openDialog } from 'store/btw/dialogSlice'
import { changeViewName } from 'apps/visualTool/store/gridSlice'

type NavListItemProps = {
  item: { id: number; title: string; type: 'change' | 'remove' }
  selected: boolean
  onItemClick: React.EventHandler<any>
}

export default function NavListItem(props: NavListItemProps) {
  const { item, selected, onItemClick } = props
  const deleteItem = { ...item, type: 'remove' }

  const [isEditing, setEditing] = useState(false)
  const [inputVal, setInputValue] = useState(item.title)

  const dispatch = useDispatch()

  function stopEditing() {
    dispatch(changeViewName({ viewId: item.id, newName: inputVal }))
    setEditing(false)
  }

  return useMemo(
    () =>
      !isEditing ? (
        <ListItem
          component="div"
          onDoubleClick={() => {
            setEditing(true)
          }}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => {
                dispatch(
                  openDialog({
                    children: (
                      <>
                        <DialogContent>
                          <DialogContentText className="font-bold">
                            뷰를 정말 삭제하시겠습니까?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => dispatch(closeDialog())} color="primary">
                            아니오
                          </Button>
                          <Button
                            onClick={() => {
                              dispatch(closeDialog())
                              onItemClick && onItemClick(deleteItem)
                            }}
                            color="primary"
                            autoFocus
                          >
                            예
                          </Button>
                        </DialogActions>
                      </>
                    ),
                  })
                )
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          }
          disablePadding
        >
          <ListItemButton selected={selected} onClick={() => onItemClick && onItemClick(item)}>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ) : (
        <ListItem disablePadding>
          <ListItemButton selected={selected}>
            <Input
              onFocus={(e) => {
                e.target.select()
              }}
              defaultValue={inputVal}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              autoFocus
              onBlur={() => {
                stopEditing()
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  stopEditing()
                }
              }}
            ></Input>
          </ListItemButton>
        </ListItem>
      ),
    [item, selected, onItemClick, isEditing, inputVal]
  )
}
