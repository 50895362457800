import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useDispatch } from 'react-redux'
import { closeDialog, openDialog } from 'store/btw/dialogSlice'
import HoverMotion from 'components/HoverMotion'

export default function RemoveBtn(props) {
  const { title, icon, onBtnClick } = props
  const dispatch = useDispatch()

  return (
    <HoverMotion>
      <Tooltip title={title}>
        <Button
          variant="text"
          size="small"
          startIcon={icon}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(
              openDialog({
                children: (
                  <>
                    <DialogContent>
                      <DialogContentText className="font-bold">
                        정말 삭제하시겠습니까?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => dispatch(closeDialog())} color="primary">
                        아니오
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(closeDialog())
                          onBtnClick && onBtnClick()
                        }}
                        color="primary"
                        autoFocus
                      >
                        예
                      </Button>
                    </DialogActions>
                  </>
                ),
              })
            )
          }}
          color="primary"
        >
          {title}
        </Button>
      </Tooltip>
    </HoverMotion>
  )
}
