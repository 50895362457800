// import VideoBG from 'components/VideoBG/VideoBG'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'

const Root = styled('div')(({ theme }) => ({
  background: theme.palette.primary.dark,
  color: theme.palette.primary.contrastText,
}))

function Hero() {
  return (
    <>
      {/* <VideoBG /> */}
      <Root className="flex flex-col h-screen flex-auto flex-shrink-0 items-center p-24 md:flex-row md:p-0">
        <div className="flex flex-col flex-grow-0 items-center p-16 text-center md:p-128 md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
          <h1 className="md:text-7xl sm:text-5xl text-3xl font-black mb-32">○△□</h1>
          <p className="text-gray-400 text-md md:text-xl mb-64">
            In general, the procedure of integrated search involves indexing enormous amounts of
            data to provide the most comprehensible and customized search. This allows all of the
            data to be available in one place, thereby making search effective and efficient
          </p>
          <Link
            className="py-24 px-24 bg-yellow-500 rounded-full text-xl text-black hover:bg-yellow-300 transition duration-300 ease-in-out flex items-center animate-bounce"
            to="/visual-tool"
          >
            <svg
              className="w-24 h-24 mr-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Get Started
          </Link>
        </div>
        <div className="w-full h-md md:h-full max-w-md mx-auto m-16 md:m-0">
          <div />
        </div>
      </Root>
    </>
  )
}

export default Hero
