import React from 'react'
import LeftNavFieldList from '../LeftNavFieldList'
import LeftNavHeader from '../LeftNavHeader'

export default function LeftNavContainer() {
  return (
    <>
      {/* <LeftNavHeader />
      <LeftNavFieldList /> */}
    </>
  )
}
