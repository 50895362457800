import React from 'react'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

interface Props {
  children: React.ReactElement
  value: number
}

function ValueLabelComponent(props: Props) {
  const { children, value } = props

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

export type BaseSliderProps = {
  setValue: any
  label: string
  name: any
  value: any
  marks: boolean
  min: number
  max: number
  step: number
}

function BaseSlider({ setValue, label, name, value, marks, min, max, step }: BaseSliderProps) {
  const handleSliderChange = (event: any, val: any) => {
    event.preventDefault()
    setValue(name, val)
  }

  return (
    <div className="flex flex-row items-center justify-start">
      <Typography gutterBottom className="w-84">
        {label}
      </Typography>
      <Slider
        valueLabelDisplay="auto"
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        aria-label="custom thumb label"
        // defaultValue={0}
        marks={marks}
        min={min}
        max={max}
        step={step}
        onChange={handleSliderChange}
        value={value}
        className="w-96"
      />
    </div>
  )
}

export default BaseSlider
