import Tab from '@mui/material/Tab'
import Input from '@mui/material/Input'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeTabName, removeTab } from '../../store/gridSlice'
import { closeDialog, openDialog } from 'store/btw/dialogSlice'

function GridTab(props) {
  const { gridName = '새 탭', gridId, index, ...tabProps } = props

  const [isEditing, setEditing] = useState(false)
  const [inputVal, setInputValue] = useState(gridName)

  const currentViewInfo = useSelector(({ feasibilityApp }) => feasibilityApp.grid.currentViewInfo)
  const { id: viewId } = currentViewInfo

  const dispatch = useDispatch()

  function stopEditing() {
    dispatch(changeTabName({ viewId, gridId, newName: inputVal }))
    setEditing(false)
  }

  return (
    <>
      {!isEditing ? (
        <Tab
          {...tabProps}
          component="div"
          onDoubleClick={(e) => {
            setEditing(true)
          }}
          label={gridName}
          {...a11yProps(index)}
          icon={
            <IconButton
              container="div"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(
                  openDialog({
                    children: (
                      <>
                        <DialogContent>
                          <DialogContentText className="font-bold">
                            [{gridName}] 을/를 정말 삭제하시겠습니까?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => dispatch(closeDialog())} color="primary">
                            아니오
                          </Button>
                          <Button
                            onClick={() => {
                              dispatch(closeDialog())
                              dispatch(removeTab(index))
                            }}
                            color="primary"
                            autoFocus
                          >
                            예
                          </Button>
                        </DialogActions>
                      </>
                    ),
                  })
                )
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          }
          iconPosition="end"
        ></Tab>
      ) : (
        <Tab
          {...tabProps}
          component="div"
          icon={
            <Input
              onFocus={(e) => {
                e.target.select()
              }}
              defaultValue={inputVal}
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              autoFocus
              onBlur={() => {
                stopEditing()
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  stopEditing()
                }
              }}
            ></Input>
          }
          iconPosition="end"
        ></Tab>
      )}
    </>
  )
}

export default React.memo(GridTab)

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  }
}
