import { useEffect, useRef } from 'react'

const useUpdateEffect = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  const isInitialMount = useRef(true)
  // eslint-disable-next-line
  useEffect(
    isInitialMount.current
      ? () => {
          isInitialMount.current = false
        }
      : effect,
    deps
  )
}

export default useUpdateEffect
