import React from 'react'
import { motion } from 'framer-motion'

const hoverMotion = {
  rest: { y: 0, opacity: 1, ease: 'easeOut', duration: 0.2, type: 'tween' },
  hover: {
    y: -1,
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
}

export type HoverMotionProps = {
  children: React.ReactNode
}

const HoverMotion = ({ children }: HoverMotionProps) => {
  return (
    <motion.div initial="rest" whileHover="hover" animate="rest">
      <motion.div variants={hoverMotion} className="cursor-pointer">
        {children}
      </motion.div>
    </motion.div>
  )
}

export default HoverMotion
