import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import { toggleDarkTheme } from 'store/btw/settingsSlice'

function ToggleDarkMode() {
  const dispatch = useDispatch()
  const themes = useSelector(({ btw }) => btw.settings.current.theme.main)
  const [theme, setTheme] = useState(themes)
  const icon = theme === 'default' ? <Brightness4Icon /> : <Brightness7Icon />

  const handleClick = () => {
    const value = theme === 'default' ? 'defaultDark' : 'default'
    setTheme(value)
    dispatch(toggleDarkTheme(value))
  }

  return (
    <Tooltip arrow title="Toggle light/dark theme">
      <IconButton onClick={handleClick}>{icon}</IconButton>
    </Tooltip>
  )
}

export default ToggleDarkMode
