import React from 'react'
import { motion } from 'framer-motion'

const container = {
  show: {
    transition: {
      staggerChildren: 0.05,
    },
  },
}

const item = {
  'auto-end': {},
  'auto-start': {},
  auto: {},
  'bottom-end': { hidden: { opacity: 0, y: 40 }, show: { opacity: 1, y: 0 } },
  'bottom-start': { hidden: { opacity: 0, y: 40 }, show: { opacity: 1, y: 0 } },
  bottom: { hidden: { opacity: 0, y: 40 }, show: { opacity: 1, y: 0 } },
  'left-end': { hidden: { opacity: 0, x: -40 }, show: { opacity: 1, x: 0 } },
  'left-start': { hidden: { opacity: 0, x: -40 }, show: { opacity: 1, x: 0 } },
  left: { hidden: { opacity: 0, x: -40 }, show: { opacity: 1, x: 0 } },
  'right-end': { hidden: { opacity: 0, x: 40 }, show: { opacity: 1, x: 0 } },
  'right-start': { hidden: { opacity: 0, x: 40 }, show: { opacity: 1, x: 0 } },
  right: { hidden: { opacity: 0, x: 40 }, show: { opacity: 1, x: 0 } },
  'top-end': { hidden: { opacity: 0, y: -40 }, show: { opacity: 1, y: 0 } },
  'top-start': { hidden: { opacity: 0, y: -40 }, show: { opacity: 1, y: 0 } },
  top: { hidden: { opacity: 0, y: -40 }, show: { opacity: 1, y: 0 } },
}

export type PopperMotionProps = {
  children: React.ReactNode
  placement: keyof typeof item
}

const PopperMotion = ({ children, placement }: PopperMotionProps) => {
  return (
    <motion.div variants={container} initial="hidden" animate="show">
      <motion.div variants={item[placement]} className="cursor-pointer">
        {children}
      </motion.div>
    </motion.div>
  )
}

export default PopperMotion
