import { createSlice, current } from '@reduxjs/toolkit'
import { generateGUID, gridData } from 'lib/utils'
import { range } from 'lodash'
import { showMessage } from 'store/btw/messageSlice'

let cache = window.localStorage.getItem('cache')

let initialState

if (cache) {
  let cacheParsed = JSON.parse(cache)
  initialState = { ...cacheParsed }
} else {
  initialState = {
    ...hardCodedState(),
  }
}

export function getCurrentView(state) {
  return state.currentViewInfo ? state.allViews[state.currentViewInfo.index] : null
}

export function getCurrentViewByViewIndex(state, viewIndex) {
  return state.currentViewInfo ? state.allViews[viewIndex] : null
}

export function getCurrentViewAllGrids(state) {
  return state?.allViews[state?.currentViewInfo?.index]?.allGrids
    ? state?.allViews[state?.currentViewInfo?.index].allGrids
    : []
}

export function updateCurrentGrids(state, gridIndex, currentGrid) {
  let currentViewAllGrids = getCurrentViewAllGrids(state)
  currentViewAllGrids = [
    ...currentViewAllGrids.slice(0, gridIndex),
    currentGrid,
    ...currentViewAllGrids.slice(gridIndex + 1),
  ]

  let currentView = getCurrentView(state)
  return { ...currentView, allGrids: currentViewAllGrids }
}

export function destroyTab(state, tabIndex) {
  let currentViewAllGrids = getCurrentViewAllGrids(state)
  currentViewAllGrids.splice(tabIndex, 1)

  let currentView = getCurrentView(state)
  currentView = { ...currentView, allGrids: currentViewAllGrids }
  deosGridExist(state)
  return { ...currentView, allGrids: currentViewAllGrids }
}

function deosGridExist(state) {
  // if all CurrentView tabIndex exists in allGrids then do nothing, else change to next available tab
  const viewIndex = state.currentViewInfo.index
  const currentView = state.allViews[viewIndex]

  const index = currentView.tabIndex
  const allGrids = currentView.allGrids

  let doesGridExist = allGrids[index]
  if (doesGridExist) return

  const nextAvailableIndex = allGrids.length - 1

  if (nextAvailableIndex !== -1) changeTab(state, nextAvailableIndex)
}

export function getViewById(state, id) {
  let viewIndex = state.allViews.findIndex((view) => {
    return view.id === id
  })

  let view = state.allViews[viewIndex]

  if (view) {
    return view
  }

  throw new Error('VIEW NOT FOUND!!')
}

export function getGridByViewAndGridId(state, viewId, gridId) {
  let view = getViewById(state, viewId)

  let gridIndex = view.allGrids.findIndex((grid) => {
    return grid.id === gridId
  })

  let grid = view.allGrids[gridIndex]

  if (grid) {
    return grid
  }

  throw new Error('GRID NOT FOUND!!')
}

const getIndexByViewId = (state, viewId) => state.allViews.findIndex((view) => view.id === viewId)

const getIndexByViewAndGridId = (state, viewIndex, gridId) =>
  state.allViews[viewIndex].allGrids.findIndex((grid) => grid.id === gridId)

const changeGridData = (state, payload) => {
  const { viewId, gridId, key, value } = payload
  const viewIndex = state.currentViewInfo?.index | 0

  let currentGrid = getGridByViewAndGridId(state, viewId, gridId)
  currentGrid[key] = value

  const gridIndex = getIndexByViewAndGridId(state, viewIndex, gridId)

  return updateCurrentGrids(state, gridIndex, currentGrid)
}

const getCurrentGrid = (state) => {
  const viewIndex = state.currentViewInfo.index
  const currentView = current(state.allViews[viewIndex])
  const tabIndex = state.allViews[viewIndex].tabIndex
  return currentView.allGrids[tabIndex]
}

const convertShape = (state, payload) => {
  let result = [...state.data]
  payload.forEach(([row, column, oldValue, newValue]) => {
    result[row][column] = newValue
  })

  return result
}

const rowToRemove = (state, payload) => {
  const { from, to } = payload

  const grid = getCurrentGrid(state)

  function deleteRow(arr, row, num) {
    arr = arr.slice(0)
    arr.splice(row, num + 1)
    return arr
  }

  const numberOfRowsToRemove = from > to ? from - to : to - from
  const startRow = from > to ? to : from

  return deleteRow(grid.data, startRow, numberOfRowsToRemove)
}
const colToRemove = (state, payload) => {
  const { from, to } = payload

  const grid = getCurrentGrid(state)

  function deleteCol(array, indexRange) {
    return array.map(function (arr) {
      return arr.filter(function (el, idx) {
        return !indexRange.includes(idx)
      })
    })
  }

  const indexRange = range(from, to + 1)

  return deleteCol(grid.data, indexRange)
}

const updateCurrentBothData = (state, value) => {
  state.gridSettings.data = value

  const grid = getCurrentGrid(state)
  const gridId = grid.id
  const viewId = state.currentViewInfo.id

  return changeGridData(state, { viewId, gridId, key: 'data', value })
}

export const saveStoreStateToLocalStorage = () => async (dispatch, getState) => {
  const state = getState().feasibilityApp.grid
  let cache = {
    currentViewInfo: state.currentViewInfo,
    allViews: [],
    gridSettings: state.gridSettings,
  }

  state.allViews.forEach((view) => {
    let cachedView = {
      id: view.id,
      tabIndex: view.tabIndex,
      allGrids: [],
      rowSelectionSyncStatus: view?.rowSelectionSyncStatus,
      name: view.name,
    }

    view.allGrids.forEach(({ id, name, data }) => {
      let cachedGrid = {
        id,
        name,
        data,
        fetchAgain: true,
      }

      cachedView.allGrids.push(cachedGrid)
    })

    cache.allViews.push(cachedView)
  })

  let cacheStringified = JSON.stringify(cache)
  try {
    localStorage.setItem('cache', cacheStringified)
    return dispatch(
      showMessage({
        message: '브라우저 로컬 스토리지에 저장하였습니다!',
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        autoHideDuration: 2500,
      })
    )
  } catch (e) {
    return dispatch(
      showMessage({
        message:
          '엑셀이 좀 크군요! 로컬 스토리지 용량 초과. 내용이나 일부 탭을 지우고 다시 시도해보세요!!',
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        autoHideDuration: 2500,
      })
    )
    // console.log('로컬 스토리지 용량 초과. 내용이나 일부 탭을 지우고 다시 시도해보세요!!')
  }
}

const gridSlice = createSlice({
  name: 'feasibilityApp/grid',
  initialState,
  reducers: {
    resetState: (state, action) => {
      state = { ...hardCodedState() }
    },
    setStateFromSheet: (state, action) => {
      const { viewName, allGrids } = action.payload
      state.allViews[0].name = viewName
      state.allViews[0].allGrids = allGrids
    },
    addNewView: (state, action) => {
      let newView = {
        id: action.payload,
        allGrids: [],
        name: '새 뷰',
      }
      state.allViews = [...state.allViews, newView]
    },
    removeView: (state, action) => {
      state.allViews = state.allViews.filter((view) => view.id !== action.payload)
    },
    changeView: (state, action) => {
      const viewId = action.payload
      const index = getIndexByViewId(state, viewId)
      const updatedCurrentViewInfo = { id: viewId, index }
      state.currentViewInfo = updatedCurrentViewInfo

      const grid = getCurrentGrid(state)
      if (grid) {
        const gridData = grid.data
        state.gridSettings.data = gridData
      }
    },
    changeViewName: (state, action) => {
      const { viewId, newName } = action.payload
      let currentView = getViewById(state, viewId)
      currentView.name = newName

      const viewIndex = getIndexByViewId(state, viewId)

      state.allViews[viewIndex] = currentView
    },
    addNewTab: (state, action) => {
      const viewIndex = state.currentViewInfo.index
      state.allViews[viewIndex].allGrids = [...state.allViews[viewIndex].allGrids, action.payload]
    },
    removeTab: (state, action) => {
      const viewIndex = state.currentViewInfo.index
      const tabIndex = action.payload
      state.allViews[viewIndex] = destroyTab(state, tabIndex)
    },
    changeTab: (state, action) => {
      const tabIndex = action.payload
      const viewIndex = state.currentViewInfo.index
      const currentView = current(state.allViews[viewIndex])
      const viewId = state.currentViewInfo.id
      const grid = currentView.allGrids[tabIndex]

      const gridId = grid.id
      const gridData = grid.data
      const fetchAgain = grid.fetchAgain

      // if (fetchAgain) {
      state.gridSettings.data = gridData
      state.allViews[viewIndex] = changeGridData(state, {
        viewId,
        gridId,
        key: 'fetchAgain',
        value: false,
      })
      // }
    },
    changeTabName: (state, action) => {
      const { viewId, gridId, newName: value } = action.payload
      const viewIndex = state.currentViewInfo.index

      state.allViews[viewIndex] = changeGridData(state, { viewId, gridId, key: 'name', value })
    },
    changeTabIndex: (state, action) => {
      const tabIndex = action.payload
      state.allViews[state.currentViewInfo.index].tabIndex = tabIndex
    },
    fetchGridData: (state, action) => {
      const { viewId, gridId, value } = action.payload
      const viewIndex = state.currentViewInfo.index

      state.allViews[viewIndex] = changeGridData(state, { viewId, gridId, key: 'data', value })
    },
    setGridSettings: (state, action) => {
      const value = convertShape(state.gridSettings, action.payload)

      const viewIndex = state.currentViewInfo.index
      state.allViews[viewIndex] = updateCurrentBothData(state, value)
    },
    setRowsToRemove: (state, action) => {
      const value = rowToRemove(state, action.payload)

      const viewIndex = state.currentViewInfo.index
      state.allViews[viewIndex] = updateCurrentBothData(state, value)
    },
    setColsToRemove: (state, action) => {
      const value = colToRemove(state, action.payload)

      const viewIndex = state.currentViewInfo.index
      state.allViews[viewIndex] = updateCurrentBothData(state, value)
    },
  },
  extraReducers: {},
})

export const {
  resetState,
  setStateFromSheet,
  addNewView,
  removeView,
  changeView,
  changeViewName,
  addNewTab,
  removeTab,
  changeTab,
  changeTabName,
  changeTabIndex,
  fetchGridData,
  setGridSettings,
  setRowsToRemove,
  setColsToRemove,
} = gridSlice.actions

export default gridSlice.reducer

function hardCodedState() {
  const newId = generateGUID()
  return {
    currentViewInfo: { id: newId, index: 0 },
    allViews: [
      {
        id: newId,
        tabIndex: 0,
        allGrids: [
          { id: generateGUID(), name: '탭 1', data: gridData, fetchAgain: true },
          { id: generateGUID(), name: '탭 2', data: gridData, fetchAgain: true },
        ],
        rowSelectionSyncStatue: 'SYNC_DONE',
        name: '뷰 1',
      },
    ],
    gridSettings: {
      data: gridData,
      rowHeaders: true,
      colHeaders: true,
      licenseKey: '77f0c-c609b-929f4-2b820-afa84',
      manualColumnResize: true,
      contextMenu: ['copy', 'alignment', 'undo', 'redo'],
      dropdownMenu: ['filter_by_condition', 'filter_by_value', 'filter_action_bar'],
      multiColumnSorting: true,
      filters: true,
      manualRowMove: true,
      manualColumnMove: true,
      outsideClickDeselects: false,
      fillHandle: {
        direction: 'vertical',
      },
      removeRowPlugin: true,
      readOnly: false,
    },
  }
}
