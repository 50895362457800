import Typography from '@mui/material/Typography'
import _ from 'lodash'
import React, { useMemo } from 'react'
import NavList from 'components/NavList'
import PopperMotion from 'components/PopperMotion'
import { useDispatch, useSelector } from 'react-redux'
import ActionButton from './ActionButton'
import { changeView, removeView, changeViewName } from '../../store/gridSlice'

function LeftMenuTable() {
  const dispatch = useDispatch()
  const allViews = useSelector(({ feasibilityApp }) => feasibilityApp.grid.allViews)
  const currentViewIndex = useSelector(
    ({ feasibilityApp }) => feasibilityApp.grid.currentViewInfo.index
  )

  const selectedIndex = useMemo(() => currentViewIndex | 0, [currentViewIndex])

  const navigation = useMemo(() => {
    return allViews.map((view) => ({
      id: view.id,
      title: view.name,
      type: 'change',
    }))
  }, [allViews])

  const handleListItemClick = (item) => {
    if (item.type === 'change') dispatch(changeView(item.id))
    if (item.type === 'remove') dispatch(removeView(item.id))
  }

  return (
    <>
      <PopperMotion placement="bottom">
        <ActionButton />
      </PopperMotion>
      <PopperMotion placement="left">
        <div className="flex-auto mt-32 p-12">
          {Object.keys(allViews) && Object.keys(allViews).length > 0 ? (
            <div className="mb-16">
              <NavList
                navigation={navigation}
                onItemClick={handleListItemClick}
                selectedId={selectedIndex}
              />
            </div>
          ) : (
            <>
              <Typography component="span" className="font-semibold flex">
                뷰가 없습니다.
              </Typography>
              <Typography className="text-11 font-medium capitalize" color="textSecondary">
                버튼을 눌러서 새 뷰를 추가하세요
              </Typography>
            </>
          )}
        </div>
      </PopperMotion>
    </>
  )
}

export default LeftMenuTable
