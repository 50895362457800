import Dialog from '@mui/material/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { closeDialog } from 'store/btw/dialogSlice'

function BtwDialog(props) {
  const dispatch = useDispatch()
  const state = useSelector(({ btw }) => btw.dialog.state)
  const options = useSelector(({ btw }) => btw.dialog.options)

  return (
    <Dialog
      open={state}
      onClose={(ev) => dispatch(closeDialog())}
      aria-labelledby="btw-dialog-title"
      classes={{
        paper: 'rounded-8',
      }}
      {...options}
    />
  )
}

export default BtwDialog
