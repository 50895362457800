// import Content from 'components/Content'
import Hero from 'components/Hero'

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Content /> */}
    </>
  )
}

export default Home
