import React from 'react'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'

import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { changeTab, changeTabIndex } from '../../store/gridSlice'

import GridTab from './GridTab'

const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
}))

export default function TabsList(props) {
  const allViews = useSelector(({ feasibilityApp }) => feasibilityApp.grid.allViews)
  const currentViewInfo = useSelector(({ feasibilityApp }) => feasibilityApp.grid.currentViewInfo)
  const tabIndex = currentViewInfo ? allViews[currentViewInfo.index]?.tabIndex : 0
  const allGrids = allViews[currentViewInfo?.index]?.allGrids
    ? allViews[currentViewInfo.index].allGrids
    : []
  const dispatch = useDispatch()

  const allTabs = mapAllGridsToTabs(allGrids)

  const [value, setValue] = React.useState(tabIndex)

  const handleChange = (e, newVal) => {
    setValue(newVal)
    dispatch(changeTab(newVal))
    dispatch(changeTabIndex(newVal))
  }

  return (
    <AppBar position="static" color="default" className="shadow-0">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        indicatorColor="secondary"
        textColor="inherit"
        allowScrollButtonsMobile
      >
        {allTabs}
      </Tabs>
    </AppBar>
  )
}

// export default withStyles(styles)(React.memo(TabsList))

//  ****
function mapAllGridsToTabs(allGrids) {
  return allGrids.map((grid, index) => {
    return <GridTab key={grid.id} gridName={grid.name} gridId={grid.id} index={index}></GridTab>
  })
}
