import React from 'react'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import ListItem, { ListItemProps } from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Link as RouterLink } from 'react-router-dom'

interface ListItemLinkProps extends ListItemProps {
  to: string
  open?: boolean
}

const breadcrumbNameMap: { [key: string]: string } = {
  '/': 'Home',
  '/integrated-search': 'Integrated Search',
  '/about': 'About',
  '/contact': 'Contact',
}

function ListItemLink(props: ListItemLinkProps) {
  const { to, open, ...other } = props
  const primary = breadcrumbNameMap[to]

  return (
    <li>
      <ListItem button component={RouterLink as any} to={to} {...other}>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  )
}

const Root = styled('div')(({ theme }) => ({
  maxWidth: 360,
  '& .lists': {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  '& .nested': {
    paddingLeft: theme.spacing(4),
  },
}))

export default function Dropdown() {
  return (
    <Root className="flex flex-col">
      <nav className="lists">
        <List>
          <ListItemLink to="/" />
          <ListItemLink to="/integrated-search" />
          <ListItemLink to="/about" />
          <ListItemLink to="/contact" />
        </List>
      </nav>
    </Root>
  )
}
