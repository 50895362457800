import React, { useState, ReactElement } from 'react'
import { styled, Theme } from '@mui/material/styles'
import MuiPopper, { PopperPlacementType } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import PopperMotion from 'components/PopperMotion'

interface Props {
  content: ReactElement
  children: ReactElement
  open: boolean
  onClose?: () => void
  arrow?: boolean
  placement?: PopperPlacementType
}
const StyledPaper = styled(Paper)(() => ({
  '&.paper': {
    minWidth: '112px',
    maxWidth: 'calc(100vw - 32px)',
    borderRadius: '6px',
  },
}))

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme }) => ({
  zIndex: 1,
  '& > div': {
    position: 'relative',
  },
  '&[data-popper-placement*="bottom"]': {
    '& > div': {
      marginTop: 2,
    },
    '& .MuiPopper-arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    '& > div': {
      marginBottom: 2,
    },
    '& .MuiPopper-arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    '& > div': {
      marginLeft: 2,
    },
    '& .MuiPopper-arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    '& > div': {
      marginRight: 2,
    },
    '& .MuiPopper-arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}))

const Arrow = styled('div')({
  position: 'absolute',
  fontSize: 7,
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid',
  },
})

const RichPopper = ({
  placement = 'top',
  arrow = true,
  open,
  onClose = () => {},
  content,
  children,
}: Props) => {
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null)
  const [childNode, setChildNode] = useState<HTMLElement | null>(null)

  return (
    <div>
      {React.cloneElement(children, { ...children.props, ref: setChildNode })}
      <Popper
        open={open}
        anchorEl={childNode}
        placement={placement}
        disablePortal={false}
        className="popper"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              boundariesElement: 'scrollParent',
            },
          },
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
          {
            name: 'hide',
            enabled: false,
          },
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: '0, 8',
            },
          },
        ]}
      >
        <PopperMotion placement={placement}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <StyledPaper className="paper">
                <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
                {content}
              </StyledPaper>
            </ClickAwayListener>
          </Paper>
        </PopperMotion>
      </Popper>
    </div>
  )
}

export default RichPopper
