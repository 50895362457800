import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import HoverMotion from 'components/HoverMotion'
import { useDispatch } from 'react-redux'
import { showMessage } from 'store/btw/messageSlice'

export default function DeleteLocalBtn() {
  const dispatch = useDispatch()
  const handleClick = () => {
    localStorage.clear()
    dispatch(
      showMessage({
        message: '브라우저 로컬 스토리지에서 모든 테이블 정보를 지웠습니다!',
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        autoHideDuration: 2500,
      })
    )
  }

  return (
    <div>
      <HoverMotion>
        <Tooltip title="브라우저에서 지우기">
          <IconButton onClick={handleClick}>
            <DeleteOutlinedIcon />
          </IconButton>
        </Tooltip>
      </HoverMotion>
    </div>
  )
}
