import { ECBasicOption } from 'echarts/types/dist/shared'
import React, { useRef, useMemo, useEffect, useState } from 'react'
import echarts from '../../lib/echarts/echarts'
import chartThemesConfig from '../../lib/theme/themesConfig'
import { diamondGradient } from '../../lib/echarts/chartUtils'
import { usePrevious } from '../../@btw/hooks'
import { pivot2d, transformData, calcHeight } from '../../lib/echarts/chartUtils'
import _ from 'lodash'
import chartColors, {
  ChartsProps,
  toolboxDefault,
  fontBold,
  fontNormal,
  labelShadow,
} from '../../lib/echarts/chartVariable'
import materialColors from 'lib/materialColors'

export interface IDynamicOptions {
  xData: any[]
  yData: any[]
  yName: string
  visualMax: number
}

const initialDynamicOptions = {
  xData: [],
  yData: [],
  yName: '',
  visualMax: 0,
}
// const colorSet = [
//   '#DC0707',
//   '#F67C20',
//   '#EFFF37',
//   '#32FFEE',
//   '#46C9FF',
//   '#54A0FF',
//   '#9fa8da',
//   '#b39ddb',
//   '#ce93d8',
//   '#bcaaa4',
// ]

function StackedBarChart({ wsData, optionValues }: ChartsProps) {
  const chartRef = useRef<echarts.ECharts | null>(null)
  const [data, setData] = useState<any[]>([])
  const [dynamicOptions, setDynamicOptions] = useState(initialDynamicOptions)

  const {
    pivot,
    item3D,
    axisX,
    axisY,
    gridLeft,
    gridBottom,
    axisXRotate,
    axisYRotate,
    widthRatio,
    heightRatio,
    theme,
    labelShow,
    labelPosition,
    itemFontSize,
    itemFontWeight,
    itemFontColor,
    labelShadowShow,
    labelShadowColor,
  } = optionValues

  const prevOptionValues = usePrevious(optionValues)
  const optionValuesChanged = !_.isEqual(optionValues, prevOptionValues)

  useEffect(() => {
    if (wsData.length === 0) return
    const foo = pivot ? pivot2d(wsData) : wsData
    const bar = transformData(foo)
    setData(bar.data2d)

    const { xData, yData, yName, visualMax } = bar
    setDynamicOptions({ xData, yData, yName, visualMax })
  }, [wsData, pivot])

  const stackedData = data.reduce((pre, cur, index) => {
    pre[index] = cur.map(
      (el: number, id: number) => +el + +(pre[index - 1] ? +pre[index - 1][id] : 0)
    )
    return pre
  }, [])

  const seriesData = useMemo(() => {
    if (data.length === 0) return null

    return data.reduce((p, c, i, array) => {
      const _color = chartColors[i] || chartColors[i % chartColors.length]

      p.push(
        {
          z: i + 1,
          stack: '총',
          type: 'bar',
          name: dynamicOptions.yData[i],
          barGap: '-100%',
          barWidth: 30,
          data: c,
          itemStyle: item3D
            ? {
                color: diamondGradient(_color),
              }
            : undefined,
        },
        {
          z: i + 10,
          type: 'pictorialBar',
          symbolPosition: 'end',
          symbol: 'diamond',
          symbolOffset: [0, '-50%'],
          symbolSize: [30, 10],
          data: stackedData[i],
          tooltip: { show: false },
          label: { show: false },
          itemStyle: item3D
            ? {
                color: diamondGradient(_color),
              }
            : undefined,
        }
      )

      // last ?
      if (p.length === array.length * 2) {
        p.push({
          z: array.length * 2,
          type: 'pictorialBar',
          symbolPosition: 'start',
          data: data[0],
          symbol: 'diamond',
          symbolOffset: ['0%', '50%'],
          symbolSize: [30, 10],
          itemStyle: item3D
            ? {
                color: diamondGradient(chartColors[0]),
              }
            : undefined,
          tooltip: { show: false },
          label: { show: false },
        })
        return p
      }

      return p
    }, [])
  }, [data, optionValues, dynamicOptions])

  const chartHeight = useMemo(() => calcHeight(heightRatio), [heightRatio])

  const chartOptions = useMemo(() => {
    const labelShadowObj = labelShadowShow ? labelShadow : undefined

    const options: ECBasicOption = {
      tooltip: {
        position: 'top',
        trigger: 'axis',
      },
      label: {
        show: labelShow,
        position: labelPosition,
        color: itemFontColor ? itemFontColor : 'inherit',
        fontSize: itemFontSize ? itemFontSize : 13,
        fontWeight: itemFontWeight ? itemFontWeight : 'normal',
        ...labelShadowObj,
        textShadowColor: labelShadowColor,
      },
      grid: {
        top: 120,
        left: gridLeft,
        bottom: gridBottom,
      },
      xAxis: {
        type: axisX,
        data: dynamicOptions.xData,
        nameTextStyle: { ...fontBold },
        axisLabel: { rotate: axisXRotate, interval: 0, ...fontBold },
      },
      yAxis: {
        type: axisY,
        axisLabel: { rotate: axisYRotate, interval: 0, ...fontBold },
      },
      series: seriesData,
      toolbox: toolboxDefault,
      brush: {},
      legend: {
        top: 50,
        left: 'auto',
        right: '10%',
        width: '80%',
        height: '60%',
        type: 'scroll',
        orient: 'horizontal',
        data: dynamicOptions.yData,
        itemWidth: 25,
        itemHeight: 15,
        itemGap: 15,
        selectedMode: false,
      },
    }
    return options
  }, [data, optionValues, dynamicOptions])

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = divRef.current
    if (!element || !chartOptions) return

    let c: echarts.ECharts | null = chartRef.current ?? null

    if (!c) {
      const chart = echarts.init(element, chartThemesConfig[theme])
      chartRef.current = chart
      c = chart
    }

    c.dispose()
    const chart = echarts.init(element, chartThemesConfig[theme])
    chartRef.current = chart
    c = chart

    c.setOption(chartOptions)

    const handleResize = () => {
      c?.resize()
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [chartOptions, optionValuesChanged])

  return (
    <div className="flex w-full h-full">
      <div
        ref={divRef}
        className={`flex ${chartHeight} ${widthRatio === 12 ? 'w-full' : `w-${widthRatio}/12`}`}
      ></div>
    </div>
  )
}

export default StackedBarChart
