import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import RichPopper from 'components/RichPopper'
import Dropdown from 'components/Dropdown'
import ToggleDarkMode from 'components/ToggleDarkMode'

export type NavbarProps = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function Navbar({ isOpen, setIsOpen }: NavbarProps) {
  const anchorRef = useRef(null)

  return (
    <Root
      className="flex text-base justify-between items-center h-48 z-10 relative"
      role="navigation"
    >
      <Link
        to="/"
        color="textPrimary"
        className="flex flex-row space-x-8 items-center font-bold pl-8"
      >
        <AssessmentTwoToneIcon fontSize="large" />
        <div>○△□</div>
      </Link>
      <div className="flex flex-row items-center">
        <RichPopper
          content={<Dropdown />}
          open={isOpen}
          placement="left-start"
          onClose={() => setIsOpen(false)}
        >
          <IconButton
            ref={anchorRef}
            aria-label="menu"
            className="md:hidden m-4"
            onClick={() => setIsOpen(!isOpen)}
          >
            <MenuIcon />
          </IconButton>
        </RichPopper>
        <div className="md:block hidden space-x-32">
          <Link color="textPrimary" to="/">
            Home
          </Link>
          <Link color="textPrimary" to="/visual-tool">
            Visual Tool
          </Link>
          <Link color="textPrimary" to="/about">
            About
          </Link>
          <Link color="textPrimary" to="/contact">
            Contact
          </Link>
          <ToggleDarkMode />
        </div>
      </div>
    </Root>
  )
}

export default Navbar

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}))
