import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import { generateGUID } from 'lib/utils'
import { useDispatch } from 'react-redux'
import { addNewView, changeView } from '../../../store/gridSlice'
import HoverMotion from 'components/HoverMotion'

export default function AddViewBtn() {
  const dispatch = useDispatch()

  const handleClick = (e) => {
    e.preventDefault()

    const newViewId = generateGUID()

    dispatch(addNewView(newViewId))
    dispatch(changeView(newViewId))
  }

  return (
    <HoverMotion>
      <Tooltip title="새 뷰 추가">
        <Button variant="outlined" size="small" startIcon={<AddIcon />} onClick={handleClick}>
          뷰
        </Button>
      </Tooltip>
    </HoverMotion>
  )
}
