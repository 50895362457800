import { createTheme } from '@mui/material/styles'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import _ from '@lodash'
import {
  defaultThemes,
  extendThemeWithMixins,
  mustHaveThemeOptions,
  defaultThemeOptions,
} from 'lib/defaultThemeSettings'
import { RootState } from 'store'

function generateMuiTheme(themes: any, id: string, direction: string) {
  const data = _.merge({}, defaultThemeOptions, themes[id], mustHaveThemeOptions)
  const response = createTheme(
    _.merge({}, data, {
      mixins: extendThemeWithMixins(data),
      direction,
    })
  )
  return response
}

const getThemes = (state: RootState) => state.btw.settings.themes
const getDirection = (state: RootState) => state.btw.settings.current.direction
const getMainThemeId = (state: RootState) => state.btw.settings.current.theme.main

export const selectMainTheme = createSelector(
  [getThemes, getDirection, getMainThemeId],
  (themes, direction, id) => generateMuiTheme(themes, id, direction)
)

const initialState = {
  current: {
    customScrollbars: true,
    direction: 'ltr',
    theme: {
      main: 'default',
    },
  },
  themes: defaultThemes,
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleDarkTheme: (state, action) => {
      state.current.theme.main = action.payload
    },
  },
})

export const { toggleDarkTheme } = settingsSlice.actions

export default settingsSlice.reducer
