import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import { SketchPicker } from 'react-color'

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color, theme }) => ({
  '& .color': {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    // background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    background: `${color}`,
  },
  '& .swatch': {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  '& .popover': {
    position: 'absolute',
    zIndex: 999,
  },
  '& .cover': {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}))

// export type ColorPickerProps = {
//   setValue: any
//   label: string
//   name: any
//   value: any
// }

const ColorPicker = ({ setValue, label, name, value }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (color) => {
    setValue(name, color.hex)
  }

  return (
    <Root color={value}>
      <div className="flex flex-row items-center justify-start">
        <Typography gutterBottom className="w-84">
          {label}
        </Typography>
        <div className="swatch" onClick={handleClick}>
          <div className="color" />
        </div>
      </div>

      {open ? (
        <div className="popover">
          <div className="cover" onClick={handleClose} />
          <SketchPicker color={value} onChange={handleChange} />
        </div>
      ) : null}
    </Root>
  )
}

export default ColorPicker
