import React from 'react'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import { styled } from '@mui/material/styles'

export type BaseSelectProps = {
  setValue: any
  label: string
  name: any
  value: any
  items: any[]
}

const StyledNativeSelect = styled(NativeSelect)(() => ({
  '& .MuiNativeSelect-select': {
    fontSize: '14px',
  },
}))

export default function BaseSelect({ setValue, label, name, value, items }: BaseSelectProps) {
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setValue(name, event.target.value)
  }

  return (
    <FormControl className="flex flex-row items-center justify-start">
      <Typography gutterBottom className="w-84">
        {label}
      </Typography>
      <StyledNativeSelect
        value={value}
        onChange={handleChange}
        name="axisY"
        className="w-96"
        inputProps={{ 'aria-label': name }}
      >
        {items.map((val: string) => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </StyledNativeSelect>
    </FormControl>
  )
}
