import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  ScatterChart,
  BarChart,
  StackedBarChart,
  PieChart,
  HeatmapChart,
  OptionContainer,
} from '@shared/components'
import SplitView from 'components/SplitView'

const fontBold = { fontSize: 13, fontFamily: 'Noto Sans KR', fontWeight: 'bold' }
const fontNormal = { fontSize: 13, fontFamily: 'Noto Sans KR', fontWeight: 'normal' }

const defaultValues = {
  theme: '',
  widthRatio: 6,
  heightRatio: 6,
  gridBottom: 250,
  gridLeft: 200,
  axisX: 'category',
  axisXRotate: 45,
  axisY: 'value',
  axisYRotate: 0,
  ratio: 6,
  pivot: false,
  labelShow: true,
  labelPosition: 'inside',
  item3D: true,
  bubbleHeatmap: false,
  colormap: 'deepPurple',
  colormapInverse: false,
  axisXFont: fontBold,
  axisYFont: fontBold,
  itemFontSize: 13,
  itemFontFamily: 'Noto Sans KR',
  itemFontWeight: 'bold',
  itemFontColor: 'inherit',
  labelShadowShow: false,
  labelShadowColor: 'rgba(0, 0, 0, 1)',
}

const chartDefault = {
  1: {
    widthRatio: 10,
    heightRatio: 7,
    axisXRotate: 0,
  },
  2: {
    widthRatio: 7,
    heightRatio: 6,
    axisXRotate: 0,
    labelPosition: 'right',
  },
  3: { labelPosition: 'outside' },
  4: { labelShadowShow: true, itemFontColor: 'rgba(255, 255, 255, 1)' },
  5: {},
}

const ChartContainer = ({ chartType }) => {
  const gridSettings = useSelector(({ feasibilityApp }) => feasibilityApp.grid.gridSettings)
  const { data } = gridSettings
  const [optionValues, setOptionValues] = useState({ ...defaultValues, ...chartDefault[chartType] })

  return (
    <div className="block w-full">
      <SplitView
        left={<OptionContainer optionValues={optionValues} setOptionValues={setOptionValues} />}
        right={
          chartType === 1 ? (
            <BarChart wsData={data} optionValues={optionValues} />
          ) : chartType === 2 ? (
            <StackedBarChart wsData={data} optionValues={optionValues} />
          ) : chartType === 3 ? (
            <PieChart wsData={data} optionValues={optionValues} />
          ) : chartType === 4 ? (
            <ScatterChart wsData={data} optionValues={optionValues} />
          ) : (
            <HeatmapChart wsData={data} optionValues={optionValues} />
          )
        }
      />
    </div>
  )
}

export default ChartContainer
