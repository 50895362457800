import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import AddIcon from '@mui/icons-material/Add'
import { generateGUID, gridData } from 'lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { addNewView, addNewTab, changeTabIndex, fetchGridData } from '../../../store/gridSlice'
import HoverMotion from 'components/HoverMotion'

export default function AddTabBtn() {
  const dispatch = useDispatch()
  const allViews = useSelector(({ feasibilityApp }) => feasibilityApp.grid.allViews)
  const currentViewInfo = useSelector(({ feasibilityApp }) => feasibilityApp.grid.currentViewInfo)

  const currentViewAllGrids = allViews[currentViewInfo.index]?.allGrids || []

  const nextTabIndex = currentViewAllGrids.length - 1
  const currentViewId = currentViewInfo.id

  const handleClick = (e) => {
    e.preventDefault()

    const isViewAvailable = currentViewInfo !== null

    let viewId = currentViewId
    // when no views are available
    if (!isViewAvailable) {
      viewId = generateGUID()
      dispatch(addNewView(viewId))
    }

    const newGridId = generateGUID()
    const newgridData = { data: gridData, id: newGridId, fetchAgain: true }

    dispatch(addNewTab(newgridData))
    dispatch(changeTabIndex(nextTabIndex))
    dispatch(fetchGridData({ viewId, gridId: newGridId, value: gridData }))
  }

  return (
    <HoverMotion>
      <Tooltip title="새 탭 추가">
        <Button
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleClick}
          color="primary"
        >
          탭
        </Button>
      </Tooltip>
    </HoverMotion>
  )
}
