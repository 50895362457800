import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { styled, useTheme, Theme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import useMediaQuery from '@mui/material/useMediaQuery'
import LeftNavContainer from './leftNav/LeftNavContainer'
import HoverMotion from 'components/HoverMotion'
import withReducer from 'store/withReducer'
import reducer from './store'

import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import TableContainer from './table/TableContainer'
import ChartContainer from './Chart/ChartContainer'

function VisualToolApp() {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const isMobile = useMediaQuery('(max-width: 760px)')

  useEffect(() => {
    if (isMobile) {
      setOpen(false)
    }
  }, [isMobile])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Root className="flex flex-col h-xl min-h-screen space-y-4">
      <AppBar
        position="relative"
        className={clsx('appBar', {
          ['appBarShift']: open,
        })}
      >
        <Toolbar>
          <HoverMotion>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx('menuButton', open && 'hide')}
            >
              <MenuIcon />
            </IconButton>
          </HoverMotion>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            onChange={handleChange}
          >
            <Tab label="테이블" />
            <Tab label="바 차트" />
            <Tab label="누적바 차트" />
            <Tab label="파이 차트" />
            <Tab label="거품 차트" />
            <Tab label="히트맵" />
          </Tabs>
        </Toolbar>
      </AppBar>
      <div className="flex flex-row">
        <Drawer
          className="drawer"
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: 'drawerPaper',
          }}
        >
          <div className="drawerHeader">
            <HoverMotion>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </HoverMotion>
          </div>
          <Divider />
          <LeftNavContainer />
        </Drawer>
        <main
          className={clsx('content', {
            ['contentShift']: open,
          })}
        >
          {value === 0 && <TableContainer />}
          {value !== 0 && <ChartContainer chartType={value} />}
        </main>
      </div>
    </Root>
  )
}

export default withReducer('feasibilityApp', reducer)(VisualToolApp)

const drawerWidth = 360

const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  '& .appBar': {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  '& .appBarShift': {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  '& .menuButton': {
    marginRight: theme.spacing(2),
  },
  '& .hide': {
    display: 'none',
  },
  '& .drawer': {
    width: drawerWidth,
    flexShrink: 0,
  },
  '& .drawerPaper': {
    top: '-64px',
    [theme.breakpoints.down('xs')]: {
      top: '-56px',
    },
    position: 'relative',
    width: drawerWidth,
  },
  '& .drawerHeader': {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  '& .content': {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  '& .contentShift': {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))
