import { useRef, useEffect } from 'react'
import SplitView from 'components/SplitView'
import { HotTable } from '@handsontable/react'
import 'handsontable/dist/handsontable.full.css'
import CustomScrollbars from 'lib/CustomScrollbars'
import { useDispatch, useSelector } from 'react-redux'
import LeftMenuTable from '../leftMenu'
import TabsList from '../tabsList/TabsList'
import { setGridSettings, setRowsToRemove, setColsToRemove } from '../../store/gridSlice'
import RemoveBtn from './RemoveBtn'
import ViewWeekIcon from '@mui/icons-material/ViewWeek'
import TableRowsIcon from '@mui/icons-material/TableRows'

export default function TableContainer(props) {
  const dispatch = useDispatch()
  const gridSettings = useSelector(({ feasibilityApp }) => feasibilityApp.grid.gridSettings)

  const tableRef = useRef(null)

  useEffect(() => {
    if (gridSettings.data) {
      scrollToBottom()
    }
  }, [gridSettings.data])

  function scrollToBottom() {
    tableRef.current.scrollTop = tableRef.current.scrollHeight
  }
  const hotRef = useRef(null)

  const onBeforeChange = (changes) => {
    dispatch(setGridSettings(changes))

    return false
  }

  const visualObjectRow = (row) => {
    const hot = hotRef.current.hotInstance
    let obj = {}
    for (let i = 0; i < hot.countCols(); i++) {
      obj[hot.colToProp(i)] = hot.getDataAtCell(row, i)
    }
    return obj
  }

  const getSelectedDataToDeleteRows = () => {
    const hot = hotRef.current.hotInstance
    const data = hot.getSelected()

    if (typeof data !== 'undefined') {
      const from = data[0][0]
      const to = data[0][2]
      let dataToDelete = []

      const getRowsDataToDelete = (from, to) => {
        for (let i = from; i <= to; i++) {
          const delete_obj = visualObjectRow(i)
          dataToDelete = [...dataToDelete, delete_obj]
        }
      }

      // if user tries to select rows from bottom to top
      if (from > to) {
        getRowsDataToDelete(to, from)
      }
      //if user tries to select rows from top to bottom or selected only single row
      else if (from < to || from === to) {
        getRowsDataToDelete(from, to)
      }
      const rowsToRemove = {
        dataToDelete,
        from,
        to,
      }
      dispatch(setRowsToRemove(rowsToRemove))
    }
  }

  const getSelectedDataToDeleteCols = () => {
    const hot = hotRef.current.hotInstance
    const data = hot.getSelected()

    if (typeof data !== 'undefined') {
      const from = data[0][1]
      const to = data[0][3]

      const colsToRemove =
        from > to
          ? {
              from: to,
              to: from,
            }
          : {
              from,
              to,
            }
      dispatch(setColsToRemove(colsToRemove))
    }
  }

  return (
    <div className="block w-full">
      <SplitView
        // left={<div />}
        left={<LeftMenuTable />}
        right={
          <div className="flex flex-col items-start w-full sm:w-2xl h-lg space-y-4">
            <TabsList />
            <CustomScrollbars
              ref={tableRef}
              className="w-full h-full overflow-y-auto overscroll-contain"
              enable={props.innerScroll}
            >
              <HotTable ref={hotRef} beforeChange={onBeforeChange} settings={gridSettings} />
            </CustomScrollbars>
            <div className="flex justify-start">
              <RemoveBtn
                onBtnClick={getSelectedDataToDeleteRows}
                title={'선택한 행 삭제'}
                icon={<TableRowsIcon />}
              />
              <RemoveBtn
                onBtnClick={getSelectedDataToDeleteCols}
                title={'선택한 열 삭제'}
                icon={<ViewWeekIcon />}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}
